import React, { useEffect, useState } from "react";
import "../CSS/Global.css";
import img1 from "../IMG/gridnull.jpg"
import Header from "./Header";
import { useParams } from "react-router-dom";
import Footer from "./Footer";

const images = [
  { id: 1, category: "Photography", src: img1 },
  { id: 2, category: "Travel", src: img1 },
  { id: 3, category: "Nature", src: img1 },
  { id: 4, category: "Fashion", src: img1 },
  { id: 5, category: "Life Style", src: img1 },
  { id: 6, category: "Photography", src: img1 },
  { id: 7, category: "Travel", src: img1 },
  { id: 8, category: "Nature", src: img1 },
  { id: 9, category: "Fashion", src: img1 },
  { id: 10, category: "Life Style", src: img1 },
  { id: 11, category: "Photography", src: img1 },
  { id: 12, category: "Travel", src: img1 },
];

const categories = ["All", "Photography", "Travel", "Nature", "Fashion", "Life Style"];

const GalleryDeep = () => {
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [selectedGalleryImages1, setSelectedGalleryImages1] = useState([]);
  const filteredImages =
    selectedCategory === "All"
      ? images
      : images.filter((image) => image.category === selectedCategory);
  const { id } = useParams();
  const [selectedTitle, setTitle] = useState();
  const [selectedDescription, setDescription] = useState();



  const fetchGalleryImages = async () => {
    try {
      const response = await fetch(`https://100billionoms.top/gallery/${id}}`);
      const res = await response.json();
      console.log(res);
      setTitle(res.gallery.title);
      setDescription(res.gallery.description);
      setSelectedGalleryImages1(res.images)

    } catch (error) {
      console.log(error);
    }
  };
  console.log(selectedGalleryImages1)
  useEffect(() => {
    fetchGalleryImages();
  }, [])

  return (
    <>
      <Header />
      <div className="gallery-container">
        {/* Header */}
        <h1>{selectedTitle}</h1>
        <p>{selectedDescription}</p>

        {/* Filter Menu */}
        {/* <div className="filter-menu">
        {categories.map((category) => (
          <button
            key={category}
            className={selectedCategory === category ? "active" : ""}
            onClick={() => setSelectedCategory(category)}
          >
            {category}
          </button>
        ))}
      </div> */}

        {/* Masonry Grid */}
        <div className="gallery-grid">
          {selectedGalleryImages1.map((image) => (
            <div key={image.id} className="gallery-item">
              <img src={`https://100billionoms.top/${image.image_url}`} alt={image.category} />
            </div>
          ))}
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default GalleryDeep;

import React, { useState } from "react";
import { FaUser, FaEnvelope, FaLock, FaEye, FaEyeSlash } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import axios from "axios";
import google from "../IMG/gm.svg";
import { toast, ToastContainer } from "react-toastify";

const Register = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    full_name: "",
    password: "",
  });
  const [emailError, setEmailError] = useState(""); // Email validation state
  const [otp, setOtp] = useState(Array(6).fill("")); // Store OTP digits in an array
  let navigate = useNavigate();
  const logintoadmission = sessionStorage.getItem("mainhometutorila");
  const [loading, setLoading] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const showModal = () => {
    document.getElementById("otpModal").style.display = "block";
  };

  const closeModal = () => {
    document.getElementById("otpModal").style.display = "none";
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    // Email validation logic
    if (name === "email") {
      const emailPattern = /^[a-zA-Z0-9._%+-]+@gmail\.com$/;
      if (!emailPattern.test(value)) {
        setEmailError("Invalid email format, please use example@gmail.com");
      } else {
        setEmailError(""); // Clear error if email is valid
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (emailError) {
      alert("Please correct the errors before submitting.");
      return;
    }

    setLoading(true); // Show loader before sending OTP

    try {
      const response = await axios.post(
        "https://100billionoms.top/user-register/api/send-otp",
        { email: formData.email }
      );

      if (response.data.success) {
        showModal();
        toast.success("OTP sent successfully");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "Failed to send OTP");
      console.error("Sign-Up Error:", error);
    } finally {
      setLoading(false); // Hide loader after request completes
    }
  };

  const verifyOtp = async (e) => {
    e.preventDefault();
    setLoading(true); // Show loader while verifying

    try {
      const enteredOtp = otp.join(""); // Combine array to get full OTP
      const response = await axios.post(
        "https://100billionoms.top/user-register/api/verify-otp",
        {
          email: formData.email,
          full_name: formData.full_name,
          password: formData.password,
          otp: enteredOtp,
        }
      );

      if (response.data.success) {
        localStorage.setItem("login", JSON.stringify(response.data.newUser));

        if (logintoadmission === "false") {
          navigate("/admission");
          localStorage.removeItem("mainhometutorila");
        } else {
          navigate("/");
        }

        closeModal();
      } else {
        toast.error("Invalid OTP. Please try again.");
      }
    } catch (error) {
      toast.error("Invalid OTP. Please try again.");
    } finally {
      setLoading(false); // Hide loader after request completes
    }
  };

  const handleInputChange = (e, index) => {
    const { value } = e.target;
    if (/^[0-9]?$/.test(value)) {
      const updatedOtp = [...otp];
      updatedOtp[index] = value;
      setOtp(updatedOtp);

      // Move focus to the next input if a digit was entered
      if (value && index < otp.length - 1) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    }
  };

  // Handle backspace and focus
  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      const updatedOtp = [...otp];
      updatedOtp[index] = "";
      setOtp(updatedOtp);
      document.getElementById(`otp-input-${index - 1}`).focus();
    }
  };

  // Handle paste event
  const handlePaste = (e) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData("text");
    const pasteValues = pasteData.slice(0, otp.length).split("");
    const updatedOtp = [...otp];

    pasteValues.forEach((digit, index) => {
      if (/^[0-9]$/.test(digit)) {
        updatedOtp[index] = digit;
      }
    });

    setOtp(updatedOtp);
  };

  const handleGoogleSuccess = async (response) => {
    try {
      const res = await axios.post(
        "https://100billionoms.top/user-register/api/google-signup",
        {
          token: response.credential,
        }
      );
      console.log(res);
      if (res.data.success) {
        if (logintoadmission === "false") {
          // console.log(res.data.user);
          navigate("/admission");
          localStorage.setItem("login", JSON.stringify(res.data.user));
          localStorage.removeItem("mainhometutorila");
          toast.success("Logged In");
        } else {
          navigate("/");
          localStorage.setItem("login", JSON.stringify(res.data.user));
          toast.success("Logged In");
        }
      } else {
        // alert(res.data.message);
        toast.error(`${res.data.message}`);
      }
    } catch (error) {
      console.log(
        "Google Sign-Up Error:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const handleGoogleFailure = (response) => {
    console.log("Handle Google Sign-Up Failure:", response);
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <div className="form">
        <div className="register-container">
          <div className="register-left login-bg">
            <h1>
              Master Skating with <span>Radical Sports Academy</span>
            </h1>
          </div>

          <div className="register-right">
            <h2>Register</h2>
            <form className="register-form" onSubmit={handleSubmit}>
              <div className="register-input-container">
                <FaUser className="register-left-icon" />
                <input
                  type="text"
                  placeholder="Full Name"
                  name="full_name"
                  value={formData.full_name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="register-input-container">
                <FaEnvelope className="register-left-icon" />
                <input
                  type="email"
                  placeholder="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              {/* Conditionally render email validation message */}
              {emailError && <p className="erroremail">{emailError}</p>}
              <div className="register-input-container">
                <FaLock className="register-left-icon" />
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={formData.password}
                  placeholder="Password"
                  onChange={handleChange}
                  required
                />
                <span onClick={togglePasswordVisibility}>
                  {showPassword ? (
                    <FaEyeSlash className="register-right-icon" />
                  ) : (
                    <FaEye className="register-right-icon" />
                  )}
                </span>
              </div>
              <button type="submit" disabled={loading} className="register-btn">
                {loading ? <span className="spinner"></span> : "Register"}
              </button>
            </form>

            <div className="register-divider">
              <span>or</span>
            </div>

            <div className="login-social">
              <GoogleOAuthProvider clientId="1097360627812-dacsadst13ll8aahbis1tbcn28hbvit5.apps.googleusercontent.com">
                <GoogleLogin
                  onSuccess={handleGoogleSuccess}
                  onFailure={handleGoogleFailure}
                  render={(renderProps) => (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Link
                        to="#"
                        onClick={renderProps.onClick}
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          src={google}
                          alt="Google Login"
                          style={{ width: "40px", height: "40px" }}
                        />
                      </Link>
                    </div>
                  )}
                />
              </GoogleOAuthProvider>
            </div>

            <div className="register-forgot-signup">
              <p>
                Already have an account?{" "}
                <button onClick={() => navigate("/login")}>Login</button>
              </p>
            </div>
          </div>

          <div id="otpModal" className="modal">
            <div className="modal-content">
              <div className="modal-header">
                <span className="modal-close" onClick={closeModal}>
                  &times;
                </span>
              </div>
              <div className="modal-body">
                <h2>Verify OTP</h2>
                <p className="phone-number">Email: {formData.email}</p>
                <div className="otp-container">
                  {otp.map((_, index) => (
                    <input
                      key={index}
                      id={`otp-input-${index}`}
                      type="number"
                      className="otp-input"
                      maxLength="1"
                      value={otp[index]}
                      onChange={(e) => handleInputChange(e, index)}
                      onKeyDown={(e) => handleKeyDown(e, index)}
                      onPaste={handlePaste}
                    />
                  ))}
                </div>
                <p className="wrong-number">
                  <a
                    href="#"
                    onClick={() =>
                      alert("Change email id functionality not implemented.")
                    }
                  >
                    Wrong email?
                  </a>
                </p>
              </div>
              <div className="modal-footer">
                <button onClick={verifyOtp} disabled={loading} className="modal-btn">
                  {loading ? <span className="spinner"></span> : "Verify OTP"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;

import '../CSS/Global.css';
import { Link, useNavigate } from 'react-router-dom';
import Roller from '../IMG/roller-skater.png'
import scheduleImg from '../IMG/Schedule.png'
import equipmentsImg from '../IMG/Equiment.png'
import eventsImg from '../IMG/Events.png'
import CentersImg from '../IMG/Center.png'
import { useEffect, useState } from 'react';

const HomeApp = () => {
    let login = JSON.parse(localStorage.getItem('login')) || '';
    const [admission, setAddmission] = useState({});


    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    // Function to fetch search results
    const handleSearch = async (query) => {
        if (!query) {
            setSearchResults([]);
            return;
        }
        setLoading(true);
        setError('');
        try {
            const response = await fetch(`https://100billionoms.top/equipment/allprod/search?search=${query}`);
            const data = await response.json();
            console.log(data);
            setSearchResults(data);
        } catch (err) {
            setError('Something went wrong! Please try again.');
        }
        setLoading(false);
    };

    // Handle input change and search dynamically
    const handleInputChange = (e) => {
        const value = e.target.value;
        setSearchQuery(value);
        handleSearch(value);
    };

    const fetchDataAdmission = async () => {
        try {
            const response = await fetch(`https://100billionoms.top/admission/singlead/${login.emplyee_ID}`)
            const res = await response.json();
            console.log(res);
            setAddmission(res);
        } catch (error) {
            console.log(error);
        }
    }

    const [daysOfWeek, setDaysOfWeek] = useState([]);
    const [dates, setDates] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [currentMonth, setCurrentMonth] = useState(new Date().getMonth()); // Manage current month
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear()); // Manage current year
    const [attendanceData, setAttendanceData] = useState([]); // Store fetched attendance data
    const [classSchedule, setClassSchedule] = useState([]); // Store fetched class schedule data
    const [nextLecture, setNextLecture] = useState(null); // To store next lecture details
    const [centerName, setCenterName] = useState(''); // To store center name
    const [coachName, setCoachName] = useState('');
    const [admissionData, setAdmissionData] = useState({});
    const navigate = useNavigate()

    const handleNavigation = (path) => {
        navigate(path);
    };

    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    // Fetch attendance data from the backend
    const fetchAbsentPresent = async () => {
        try {
            const response = await fetch(`https://100billionoms.top/studentattendance/singlestudent/${login.emplyee_ID}`);
            const res = await response.json();
            console.log("Attendance Data:", res);
            setAttendanceData(res || []);
        } catch (error) {
            console.log("Error fetching attendance data:", error);
        }
    };


    // Fetch admission details to get class schedule
    const fetchAdmissiondetails = async () => {
        try {
            const response = await fetch(`https://100billionoms.top/admission/${login.emplyee_ID}`);
            const res = await response.json();
            console.log(res)
            setAdmissionData(res);
            if (response.ok) {
                fetchClassSchedule(res.age, res.center_name, res.class_level); // Fetch class schedule based on admission details
            }
        } catch (error) {
            console.log(error);
        }
    };

    // Fetch class schedule for the student
    const fetchClassSchedule = async (age, center, classLevel) => {
        try {

            console.log(age, center, classLevel)
            const response = await fetch(`https://100billionoms.top/classschedule/studentstime?age=${age}&center=${center}&classLevel=${classLevel}`);
            const res = await response.json();
            console.log("Class Schedule Response:", res);

            if (!res || res.length === 0) {
                console.log("No schedule found");
                return;
            }

            setClassSchedule(res); // Store the class schedule
            setCenterName(center); // Set the center name
            setCoachName(res[0]?.class_coachname || ""); // Use the first schedule's coach name
        } catch (error) {
            console.log("Error fetching class schedule:", error);
        }
    };


    // Function to get all dates in the current month that match the class day
    // Function to find the next lecture





    // const calculateDuration = (start, end) => {
    //     const startTime = new Date(`1970-01-01T${start}`);
    //     const endTime = new Date(`1970-01-01T${end}`);
    //     const diff = (endTime - startTime) / 60000; // Convert to minutes

    //     console.log("Start Time:", startTime);
    //     console.log("End Time:", endTime);
    //     return `${diff} Minutes`;
    // };

    useEffect(() => {
        fetchAbsentPresent();
        fetchAdmissiondetails();
    }, []);


    const dayMap = {
        'Sunday': 0,
        'Monday': 1,
        'Tuesday': 2,
        'Wednesday': 3,
        'Thursday': 4,
        'Friday': 5,
        'Saturday': 6
    };
    // Function to filter and get upcoming lectures
    const getUpcomingLectures = (classSchedule) => {
        const today = new Date(); // Current date and time
        const upcomingLectures = [];

        if (!classSchedule || classSchedule.length === 0) {
            console.log("Class schedule is empty or undefined");
            return [];
        }

        classSchedule.forEach(schedule => {
            const coachDetails = typeof schedule.class_coach_details === 'string'
                ? JSON.parse(schedule.class_coach_details)
                : schedule.class_coach_details;

            const parsedFrequency = typeof schedule.frequency === 'string'
                ? JSON.parse(schedule.frequency)
                : schedule.frequency;

            if (!parsedFrequency || parsedFrequency.length === 0) {
                console.log(`No frequency found for class: ${schedule.class_name}`);
                return;
            }

            parsedFrequency.forEach(freq => {
                const dayOfWeekIndex = dayMap[freq.day];
                const scheduledDates = getScheduledDates(dayOfWeekIndex);

                scheduledDates.forEach(scheduledDate => {
                    const lectureDate = new Date(currentYear, currentMonth, scheduledDate);

                    const dateString = `${lectureDate.getFullYear()}-${String(lectureDate.getMonth() + 1).padStart(2, '0')}-${String(lectureDate.getDate()).padStart(2, '0')}`;
                    const startTime = new Date(`${dateString}T${freq.startTime}`);
                    const endTime = new Date(`${dateString}T${freq.endTime}`);

                    if (startTime >= today) {
                        const coachName = Array.isArray(coachDetails)
                            ? coachDetails.map(coach => `${coach.firstName} ${coach.lastName}`).join(', ')
                            : `${coachDetails?.firstName || ''} ${coachDetails?.lastName || ''}`;

                        upcomingLectures.push({
                            date: lectureDate,
                            start_time: startTime,
                            end_time: endTime,
                            class_name: schedule.class_name,
                            coach_name: coachName
                        });
                    }
                });
            });
        });

        console.log("Upcoming Lectures:", upcomingLectures);
        return upcomingLectures;
    };

    const getScheduledDates = (dayOfWeek) => {
        const firstDay = new Date(currentYear, currentMonth, 1);
        const lastDay = new Date(currentYear, currentMonth + 1, 0);
        const scheduledDates = [];

        for (let i = 1; i <= lastDay.getDate(); i++) {
            const date = new Date(currentYear, currentMonth, i);
            if (date.getDay() === dayOfWeek) {
                scheduledDates.push(i); // Add this day as a scheduled date
            }
        }

        return scheduledDates;
    };

    // Modify the existing useEffect to include this logic
    useEffect(() => {
        const firstDay = new Date(currentYear, currentMonth, 1);
        const lastDay = new Date(currentYear, currentMonth + 1, 0);
        const numDays = lastDay.getDate();

        const daysOfWeekArray = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        setDaysOfWeek(daysOfWeekArray);

        const datesArray = [];
        for (let i = 0; i < firstDay.getDay(); i++) {
            datesArray.push(''); // Fill empty spots for non-existing dates
        }

        // Fetch and filter upcoming lectures
        const upcomingLectures = getUpcomingLectures(classSchedule);
        console.log(attendanceData)
        // Loop over the days of the current month and assign classes
        for (let i = 1; i <= numDays; i++) {
            const currentDate = new Date(currentYear, currentMonth, i);
            let className = 'student-date';

            // Check if attendance data exists for the current date
            const attendanceForDay = attendanceData.find(attendance => {
                const attendanceDate = new Date(attendance.date);
                return (
                    attendanceDate.getFullYear() === currentYear &&
                    attendanceDate.getMonth() === currentMonth &&
                    attendanceDate.getDate() === i
                );
            });

            // If attendance exists, set class to present or absent
            if (attendanceForDay) {
                className += attendanceForDay.status === 'Present' ? ' student-present' : ' student-absent';
            } else {
                // Check if this day is a scheduled class in the future
                const today = new Date(); // Current date (today)

                // Loop through each class in the classSchedule and only consider future classes
                classSchedule.forEach(schedule => {
                    schedule.frequency.forEach(freq => {
                        const dayOfWeekIndex = dayMap[freq.day]; // Map day name to index
                        const scheduledDates = getScheduledDates(dayOfWeekIndex);

                        // If the current date is in the future or today, add student-scheduled class
                        if (scheduledDates.includes(i) && currentDate.setHours(0, 0, 0, 0) >= today.setHours(0, 0, 0, 0)) {
                            className += ' student-scheduled'; // Mark the date as a future scheduled class
                        }
                    });
                });

                // Check if the current day matches an upcoming class
                upcomingLectures.forEach(lecture => {
                    const lectureDate = new Date(lecture.start_time).getDate();
                    if (lectureDate === i) {
                        className += ' upcoming-lecture'; // Highlight upcoming class
                    }
                    // console.log(lectureDate)
                });
            }

            datesArray.push({ day: i, className });
        }

        setDates(datesArray);


    }, [currentYear, currentMonth, attendanceData, classSchedule]);


    // useEffect(() => {
    //     const today = new Date(); // Get today's date

    //     // Filter the upcoming lectures
    //     const upcomingLectures = getUpcomingLectures(classSchedule);
    //     console.log(upcomingLectures);
    //     // setNextLecture(upcomingLectures[0]);
    //     // Sort the lectures by start time to get the nearest upcoming one
    //     const sortedLectures = upcomingLectures.sort((a, b) => new Date(a.start_time) - new Date(b.start_time));

    //     // Set the first lecture in the sorted list as the next upcoming lecture
    //     if (sortedLectures.length > 0) {
    //         setNextLecture(sortedLectures[0]);
    //     }
    // }, [classSchedule]);

    useEffect(() => {
        if (classSchedule.length > 0) {
            const upcomingLectures = getUpcomingLectures(classSchedule);
            if (upcomingLectures.length > 0) {
                const sortedLectures = upcomingLectures.sort((a, b) => new Date(a.start_time) - new Date(b.start_time));
                setNextLecture(sortedLectures[0]); // Set the next lecture
            } else {
                setNextLecture(null); // No upcoming lectures
            }
        }
    }, [classSchedule]);

    console.log(nextLecture)
    // Function to calculate duration between start and end times
    const calculateDuration = (startTime, endTime) => {
        if (!startTime || !endTime) {
            return "N/A";
        }
        const start = new Date(startTime);
        const end = new Date(endTime);
        const diff = (end - start) / (1000 * 60); // Convert to minutes

        return `${diff} Minutes`;
    };


    const handleDateClick = (day) => {
        setSelectedDate(day);
    };

    const handlePrevMonth = () => {
        if (currentMonth === 0) {
            setCurrentMonth(11);
            setCurrentYear(currentYear - 1);
        } else {
            setCurrentMonth(currentMonth - 1);
        }
    };

    const handleNextMonth = () => {
        if (currentMonth === 11) {
            setCurrentMonth(0);
            setCurrentYear(currentYear + 1);
        } else {
            setCurrentMonth(currentMonth + 1);
        }
    };


    const getLowestPriceDetails = (variations) => {
        if (!variations) return { regularPrice: null, salePrice: null, imageUrl: null };

        try {
            const parsedVariations = JSON.parse(variations);
            if (!Array.isArray(parsedVariations) || parsedVariations.length === 0) {
                return { regularPrice: null, salePrice: null, imageUrl: null };
            }

            // Sort variations by sale price (or regular price if sale price is missing)
            const sortedVariations = parsedVariations.sort((a, b) => Number(a.salePrice || a.price) - Number(b.salePrice || b.price));

            const lowestVariation = sortedVariations[0]; // Get the lowest price variation

            return {
                regularPrice: Number(lowestVariation.price) || 0,
                salePrice: Number(lowestVariation.salePrice) || Number(lowestVariation.price) || 0,
                imageUrl: lowestVariation.imageUrl ? `https://100billionoms.top${lowestVariation.imageUrl}` : 'https://via.placeholder.com/150',
            };
        } catch (error) {
            console.error("Error parsing variations:", error);
            return { regularPrice: null, salePrice: null, imageUrl: null };
        }
    };


    useEffect(() => {
        fetchDataAdmission();

    }, []);

    const handleNavigate = () => {
        sessionStorage.setItem("mainhometutorila", "false")
        navigate("/login")
    }


    return (
        <>
            <div className="home-app">
                <div className="home-app-header">
                    <h1>Hey <span>{login.full_name}</span>,</h1>
                    <p>Roll into fun and fitness!</p>
                    <div className="home-app-search">
                        <input
                            type="text"
                            placeholder="Search Equipment..."
                            value={searchQuery}
                            onChange={handleInputChange}
                        />
                        <i className="fa-solid fa-magnifying-glass"></i>
                    </div>
                </div>
            </div>

            {/* Search Results */}
            {/* <div className="search-results-container">
                {loading && <p className="loading-text">Loading...</p>}
                {error && <p className="error-text">{error}</p>}
                
                {searchResults.length > 0 ? (
                    <div className="search-list">
                        {searchResults.map((item, index) => (
                            <div className="search-item" key={index}>
                                <img
                                    src={item.product_image ? `https://100billionoms.top/${item.product_image}` : 'https://via.placeholder.com/150'}
                                    alt={item.equipment_name}
                                    className="search-item-image"
                                />
                                <div className="search-item-content">
                                    <h3>{item.equipment_name}</h3>
                                    <p className="category">{item.category}</p>
                                    <p className="description">{item.description.substring(0, 60)}...</p>
                                    <div className="price">
                                        {item.sale_price ? (
                                            <>
                                                <span className="sale-price">₹{item.sale_price}</span>
                                                <span className="regular-price">₹{item.regular_price}</span>
                                            </>
                                        ) : (
                                            <span className="regular-price">₹{item.regular_price}</span>
                                        )}
                                    </div>
                                    <button className="view-details-btn">View Details</button>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : searchQuery.length > 0 && !loading ? (
                    <p className="no-results">No products found</p>
                ) : null}
            </div> */}
            <div className='searchmaindivop'>
                {searchResults.map((item, index) => {
                    const { regularPrice, salePrice, imageUrl } = getLowestPriceDetails(item.variation);

                    return (
                        <Link to={`/product-page/${item.id}`}>
                            <div className="search-item" key={index}>
                                {/* Product Image from lowest price variation */}
                                <div>
                                    <img src={imageUrl} alt={item.equipment_name} className="search-item-image" />
                                </div>
                                <div className="search-item-content">
                                    <h3>{item.equipment_name}</h3>

                                    <p className="descriptionseacrch">{item.description.substring(0, 60)}...</p>

                                    {/* Display Regular & Sale Prices */}
                                    <div className="priceseacrch">
                                        {(salePrice && regularPrice) && (
                                            <>
                                                <span className="regular-priceseacrch">₹{regularPrice}</span>
                                                <span className="sale-priceseacrch">₹{salePrice}</span>
                                            </>
                                        )}
                                    </div>

                                    {/* <button className="view-details-btn">View Details</button> */}
                                </div>
                            </div>
                        </Link>
                    );
                })}
            </div>
            {
                (admission.status === "Approved" && login) ? <div className="home-app-schedule">
                    <div className="home-app-section-header">
                        <h2>Schedule</h2>
                        <Link to='/schedule'>
                            <button className="home-app-view-allbtn">View more <i className="fa-solid fa-chevron-right"></i></button>
                        </Link>
                    </div>
                    {
                        nextLecture && (
                            <div className="home-app-schedule-list">
                                <div className="home-app-center-map">
                                    <p className="home-app-center-name">{nextLecture.class_name} {nextLecture.coach_name}</p>
                                    <i className="fa-solid fa-route"></i>
                                </div>
                                <div className="home-app-time-duration">
                                    <p className="home-app-start-time">Start Time<div>{nextLecture.start_time.toLocaleTimeString()}</div></p>
                                    <p className="home-app-duration">Duration<div>{calculateDuration(nextLecture.start_time, nextLecture.end_time)}</div></p>
                                </div>
                            </div>
                        )
                    }

                </div>
                    : null
            }

            <div className="home-app-feature">
                <div className="home-app-section-header">
                    <h2>Features</h2>
                </div>
                <div className="home-app-features-cards">
                    <div className="home-app-feature-list" onClick={() => handleNavigation("/schedule")}>
                        <h3>Class schedule</h3>
                        <img src={scheduleImg} alt="Schedule" />
                    </div>
                    <div className="home-app-feature-list" onClick={() => handleNavigation("/equipment")}>
                        <h3>Equipments</h3>
                        <img src={equipmentsImg} alt="Equipments" />
                    </div>
                    <div className="home-app-feature-list" onClick={() => handleNavigation("/gallery")}>
                        <h3>Upcoming events</h3>
                        <img src={eventsImg} alt="Upcoming Events" />
                    </div>
                    <div className="home-app-feature-list" onClick={() => handleNavigation("/centers")}>
                        <h3>Our centers</h3>
                        <img src={CentersImg} alt="Our Centers" />
                    </div>
                </div>
            </div>
            {
                (admission.status === "Approved" && login) ? null
                    : <div className="home-app-enroll">
                        <p>Welcome to Radical Sports!</p>
                        <div className="home-app-section-header">
                            <h2>Unlock Your Potential! 🎉 Take the First Step Toward Excellence</h2>
                        </div>
                        <div className="home-app-enroll-btn-img">
                            <p onClick={handleNavigate}>
                                <Link to="#">
                                    <button>Enroll now!</button>
                                </Link>
                            </p>
                            <img src={Roller} alt="" />
                        </div>
                    </div>
            }
            <div className="home-app-footer">
                <h3>Empowering Your Ride, Revolutionizing Skating</h3>
                <p>Created with love <i className="fa-solid fa-heart"></i> <a href="https://www.100billiontech.com/">100billion Tech</a></p>
            </div>
        </>
    );
};

export default HomeApp;

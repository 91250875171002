import React, { useEffect, useState } from "react";
import "../CSS/Global.css"; // Custom CSS for styling
import Header from "./Header";
import Footer from "./Footer";
import { toast, ToastContainer } from "react-toastify";
import img1 from "../IMG/gridnull.jpg";
import Centers from "./Centers";

const ContactUs = () => {
  let login = JSON.parse(localStorage.getItem("login")) || "";
  const [data, setData] = useState({
    name: "",
    parent_name: "",
    email: "",
    phone: "",
    age: "",
    level: "",
    remark: "",
    student_id: login.emplyee_ID,
    mobile: login.full_name,
  });

  const offices = [
    {
      id: 1,
      name: "Malad",
      address:
        "Malad, Upper Govind Nagar, Malad (E, Mumbai, Maharashtra 400097",
      type: "Corporate office",
      image: img1,
    },
    {
      id: 2,
      name: "Goregaon",
      address:
        "Rosary Church Campus, M.G. Road Cardinal Gracias Nagar, Goregaon West, Mumbai, Maharashtra 400104",
      type: "Center office",
      image: img1,
    },
    {
      id: 3,
      name: "Malad",
      address:
        "Kailash Puri, Plot No. 139, Malad, Upper Govind Nagar, Malad (E, Mumbai, Maharashtra 400097",
      type: "Center office",
      image: img1,
    },
    {
      id: 4,
      name: "Malad",
      address:
        "Kailash Puri, Plot No. 139, Malad, Upper Govind Nagar, Malad (E, Mumbai, Maharashtra 400097",
      type: "Center office",
      image: img1,
    },
    {
      id: 5,
      name: "Mumbai",
      address: "5th floor, Andheri East, Mumbai - 400059.",
      type: "Center office",
      image: img1,
    },
    {
      id: 6,
      name: "Mumbai",
      address: "Ground floor, Hitech City, Mumbai - 400081.",
      type: "Center office",
      image: img1,
    },
  ];

  const [message, setMessage] = useState("");
  const [classname, setclassname] = useState([]);
  const [loading, setLoading] = useState(false);
  const maxChars = 500;

  const handleMessageChange = (e) => {
    if (e.target.value.length <= maxChars) {
      setMessage(e.target.value);
    }
  };

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Show loader

    try {
      const response = await fetch("https://100billionoms.top/contact-us", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          name: data.name,
          email: data.email,
          age: data.age,
          level: data.level,
          parent_name: data.parent_name,
          remark: message,
          student_id: data.student_id,
          mobile: data.mobile,
        }),
      });

      if (response.ok) {
        toast.success("Thank you for contacting!");
      } else {
        toast.error("Submission failed. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const fetchCenter = async () => {
    try {
      const response = await fetch(
        `https://100billionoms.top/classlevel/admin1997`
      );
      let res = await response.json();
      console.log(res);
      setclassname(res);
    } catch (error) {
      console.log(error);
    }
  };

  const activeClass = classname.filter(
    (center_name) => center_name.status === "Active"
  );

  const options2 = activeClass.map((language) => ({
    value: language.class_level,
    label: `${language.class_level}`,
  }));
  console.log(data, message);

  useEffect(() => {
    fetchCenter();
  }, []);

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <Header />
      <section className="contact-contact-us">
        <div className="contact-contact-header">
          <h2>Get In Touch</h2>
        </div>
        <div className="contact-contact-content">
          <div className="contact-contact-form">
            <h3>Send us a Message</h3>
            <form onSubmit={handleSubmit}>
              <div className="contact-form-group">
                <label>
                  <i className="fa-solid fa-user"></i> Name of Parent
                </label>
                <input
                  type="text"
                  name="parent_name"
                  value={data.parent_name}
                  onChange={handleChange}
                  placeholder="Enter your Parent Name"
                  required
                />
              </div>
              <div className="contact-form-group">
                <label>
                  <i className="fa-solid fa-envelope"></i> Email
                </label>
                <input
                  type="email"
                  name="email"
                  value={data.email}
                  onChange={handleChange}
                  placeholder="Enter your email"
                  required
                />
              </div>
              <div className="contact-form-group">
                <label>
                  <i className="fa-solid fa-phone"></i> Phone
                </label>
                <input
                  type="number"
                  name="mobile"
                  value={data.mobile}
                  onChange={handleChange}
                  placeholder="Enter your Phone"
                  required
                />
              </div>
              <div className="contact-form-group">
                <label>
                  <i className="fa-solid fa-user"></i> Name
                </label>
                <input
                  type="text"
                  name="name"
                  value={data.name}
                  onChange={handleChange}
                  placeholder="Enter your name"
                  required
                />
              </div>
              <div className="contact-form-group">
                <label>
                  <i className="fa-solid fa-birthday-cake"></i> Age
                </label>
                <input
                  type="number"
                  name="age"
                  value={data.age}
                  onChange={handleChange}
                  placeholder="Enter your Age"
                  required
                />
              </div>

              <div className="contact-form-group">
                <label>
                  <i className="fa-solid fa-signal"></i> Level
                </label>
                <select
                  id="school"
                  name="level"
                  value={data.level}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select Level</option>
                  {options2.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>

              <div className="contact-form-group">
                <label>
                  <i className="fa-solid fa-message"></i> Message
                </label>
                <textarea
                  rows="4"
                  placeholder="Your message"
                  value={message}
                  onChange={handleMessageChange}
                ></textarea>
                <p
                  style={{
                    color: message.length === maxChars ? "red" : "black",
                    textAlign: "right",
                  }}
                >
                  {message.length}/{maxChars} characters
                </p>
              </div>

              <button
                type="submit"
                disabled={loading}
                className="contact-submit-btn"
              >
                {loading ? <span className="spinner"></span> : "Send message"}
              </button>
            </form>
          </div>

          <div className="contact-contact-info">
            <h3>Contact Information</h3>
            <p>
              <i className="fa-solid fa-building"></i> Malad East Mumbai India
              400097
            </p>
            <p>
              <i className="fa-solid fa-phone"></i> +919820163394
            </p>
            <p>
              <i className="fa-solid fa-envelope"></i>{" "}
              radicalskatingclasses@gmail.com{" "}
            </p>
            <div className="contact-social-icons">
              <i className="fa-brands fa-twitter"></i>
              <i className="fa-brands fa-linkedin"></i>
              <i className="fa-brands fa-instagram"></i>
            </div>
          </div>
        </div>
        <div className="contact-map-container">
          <iframe
            title="Google Map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3153.008701731468!2d-122.40046238468197!3d37.78830057975811!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8085809d97e1c6c7%3A0x4b16c59b1d4d3d56!2sSalesforce%20Tower!5e0!3m2!1sen!2sus!4v1625142603066!5m2!1sen!2sus"
            width="600"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>
      </section>
      <Centers />
      <Footer />
    </>
  );
};

export default ContactUs;

import '@fortawesome/fontawesome-free/css/all.min.css';
import './App.css';
import Home from './Components/Home';
import { Routes, Route } from 'react-router-dom';
import Login from './Components/Login';
import Register from './Components/Register';
import Forgot from './Components/Forgot';
import Admission from './Components/AdmissionForm';
import Enquiry from './Components/EnquiryForm'
import Payment from './Components/Payment'
import Schedule from './Components/Schedule'
import Profile from './Components/Profile'
import Contact from './Components/Contact'
import About from './Components/About'
import Products from './Components/Products';
import Cart from './Components/Cart';
import Address from './Components/Address';
import EditAddress from './Components/EditAddress';
import AllRoute from './Components/AllRoute/AllRoute';
import ScrollToTop from './Components/ScrollToTop';

function App() {
  return (
    <>
      <AllRoute />
      <ScrollToTop/>
    </>
  );
}

export default App;

import React, { useState, useRef, useEffect } from 'react';
import '../CSS/Global.css'; // Ensure the CSS file matches this path
import Header from './Header';
import Footer from './Footer';
import img1 from "../IMG/img1.jpg";
import PaymentComponent from '../Components/Payment'; // Import the PaymentComponent
import { toast, ToastContainer } from 'react-toastify';

const ProfileComponent = () => {
  const [activeTab, setActiveTab] = useState('profileUserInfo'); // Tracks the active tab
  const [isEditing, setIsEditing] = useState(false); // Tracks editing mode
  const [isEditing1, setIsEditing1] = useState(false);
  const [showModal, setShowModal] = useState(false); // Controls modal visibility
  const [helpMessage, setHelpMessage] = useState(''); // Stores the help message from the text area
  const fileInputRef = useRef(null);
  const [profileFile, setProfileFile] = useState(null);
  const fileInputRef1 = useRef(null);
  const [profileFile1, setProfileFile1] = useState(null);

  const [admission, setAdmissionForm] = useState({
    full_name: '',
    email: '',
    phone: '',
    dob: '',
    gender: "",
    center_name: "",
    class_level: '',
    Students_id: '',
    photo: '',
    status: '',
    emregency_contact: "",
    father_name: "",
    father_phone: "",
    father_occupation: "",
    mother_name: "",
    nationality: ""
  });
  const [center, setCenter] = useState([]);
  const [admission1, setAdmissionForm1] = useState({
    full_name: '',
    email: '',
    profilePic: '',
  });
  const [load, setLoad] = useState(false);

  let login = JSON.parse(localStorage.getItem('login')) || '';

  const fetchAdmissionForm = async () => {
    try {
      const response = await fetch(`https://100billionoms.top/admission/singlead/${login.emplyee_ID}`);
      const res = await response.json();
      const pricing_plan = Array.isArray(res.pricing_plan) ? res.pricing_plan : JSON.parse(res.pricing_plan || "[]");
      const timing = Array.isArray(res.timing) ? res.timing : JSON.parse(res.timing || "[]");
      setAdmissionForm({
        ...res,
        pricing_plan,
        timing
      });
    } catch (error) {
      console.error(error);
    }
  };

  const fetchDataUser = async () => {
    try {
      const response = await fetch(`https://100billionoms.top/user-register/single/${login.emplyee_ID}`);
      const res = await response.json();
      setAdmissionForm1(res);
      localStorage.setItem("login", JSON.stringify(res));
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCenterList = async () => {
    try {
      const response = await fetch(`https://100billionoms.top/centerlist/admin1997`)
      const res = await response.json();
      console.log(res);
      setCenter(res);
    } catch (error) {
      console.log(error);
    }
  }

  const activeClass = center.filter(center_name => center_name.status === 'Active');

  const options2 = activeClass.map(language => ({
    value: language.center_name,
    label: (`${language.center_name}`),
  }));




  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Store the file object for later upload
      setProfileFile(file);
    }
  };

  const handleFileChange1 = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Store the file object for later upload
      setProfileFile1(file);
    }
  };

  // Toggle the editing mode
  const toggleEditMode = () => {
    setIsEditing(!isEditing);
  };

  const toggleEditMode1 = () => {
    setIsEditing1(!isEditing1);
  };

  // Handle input changes for text fields
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setAdmissionForm1((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleInputChange1 = (event) => {
    const { name, value } = event.target;
    setAdmissionForm((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  // Save profile changes (including file upload via FormData)
  const handleSave = async () => {
    try {
      const formData = new FormData();
      // Append text fields
      formData.append('full_name', admission1.full_name);
      formData.append('email', admission1.email);
      // Append the image file if one was selected
      if (profileFile) {
        formData.append('profilePic', profileFile);
      }
      // Send the formData using fetch
      const response = await fetch(`https://100billionoms.top/user-register/change/${login.emplyee_ID}`, {
        method: 'PUT',
        body: formData
      });
      const updatedData = await response.json();
      setAdmissionForm1(updatedData);
      fetchDataUser();
      setIsEditing(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSave1 = async () => {
    try {
      let formData;

      // Check if a file is uploaded
      if (profileFile1) {
        formData = new FormData();
        formData.append('full_name', admission.full_name);
        formData.append('email', admission.email);
        formData.append('phone', admission.phone);
        formData.append('dob', admission.dob);
        formData.append('gender', admission.gender);
        formData.append('center_name', admission.center_name);
        formData.append('class_level', admission.class_level);
        formData.append('emregency_contact', admission.emregency_contact);
        formData.append('father_name', admission.father_name);
        formData.append('father_phone', admission.father_phone);
        formData.append('father_occupation', admission.father_occupation);
        formData.append('mother_name', admission.mother_name);
        formData.append('nationality', admission.nationality);
        formData.append('photo', profileFile1);
      } else {
        formData = {
          full_name: admission.full_name,
          email: admission.email,
          phone: admission.phone,
          dob: admission.dob,
          gender: admission.gender,
          center_name: admission.center_name,
          class_level: admission.class_level,
          emregency_contact: admission.emregency_contact,
          father_name: admission.father_name,
          father_phone: admission.father_phone,
          father_occupation: admission.father_occupation,
          mother_name: admission.mother_name,
          nationality: admission.nationality
        };
      }

      console.log("Sending Data to Backend:", formData);

      const response = await fetch(`https://100billionoms.top/admission/change/${login.emplyee_ID}`, {
        method: 'PUT',
        headers: profileFile1 ? {} : { 'Content-Type': 'application/json' },
        body: profileFile1 ? formData : JSON.stringify(formData)
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const updatedData = await response.json();
      console.log("Response from Backend:", updatedData);

      setAdmissionForm(updatedData);
      fetchAdmissionForm();
      setIsEditing1(false);
      toast.success("Profile updated successfully!");

    } catch (error) {
      console.error("Error updating profile:", error);
      toast.error("Failed to update profile. Please try again.");
    }
  };


  // Modal handlers
  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const handleSubmitHelp = async () => {
    // Here you can send the helpMessage to your backend if needed.
    // console.log("Help Message Submitted:", helpMessage);
    setLoad(true);
    try {
      const response = await fetch(`https://100billionoms.top/help`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          name: login.full_name,
          student_id: login.emplyee_ID,
          remark: helpMessage
        })
      })
      if (response.ok) {
        const res = await response.json();
        console.log(res);
        setHelpMessage('');
        closeModal();
        toast.success('Our team will contact you soon');
        setLoad(false);
      }
      setLoad(false);
    } catch (error) {
      console.log(error);
      setLoad(false);
    }
    // Clear the message and close the modal after submission.

  };

  useEffect(() => {
    fetchAdmissionForm();
    fetchDataUser();
    fetchCenterList();
  }, []);

  useEffect(() => {
    const handleTabChangeEvent = (e) => {
      const { tab } = e.detail; // Get the tab from the event's detail
      setActiveTab(tab);
    };

    window.addEventListener('tabChange', handleTabChangeEvent);
    return () => {
      window.removeEventListener('tabChange', handleTabChangeEvent);
    };
  }, []);

  useEffect(() => {
    // Set active tab based on URL query parameter
    const queryParams = new URLSearchParams(window.location.search);
    const tab = queryParams.get('tab') || 'profileUserInfo';
    setActiveTab(tab);
  }, [window.location.search]);

  const handleTabChangeLocal = (tab) => {
    setActiveTab(tab); // Update active tab state
    window.history.pushState({}, '', `/profile?tab=${tab}`); // Update URL without page reload
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss

        pauseOnHover
        theme="colored"
      />
      <Header />
      <div className="profile-container">
        <div className="profile-content">
          {/* Column 1 */}
          <div className="profile-column profile-column-1">
            <div className="profile-box">
              <div className="profile-name">
                <strong>{admission1.full_name}</strong>
              </div>
              <div className="profile-image-container">
                <img
                  src={
                    admission1.profilePic && (admission1.profilePic.startsWith("http") || admission1.profilePic.startsWith("data:"))
                      ? admission1.profilePic
                      : admission1.profilePic && admission1.profilePic.startsWith("Images/StaffProfile/")
                        ? `https://100billionoms.top/${admission1.profilePic}`
                        : img1
                  }
                  alt="Profile"
                  className="profile-image"
                />
              </div>
              {/* {
                admission?.status ? <button
                  className={
                    admission.status === 'Pending'
                      ? 'admissionbutton'
                      : 'admissionapprove'
                  }
                >
                  {admission.status}
                </button> : null
              } */}

              {/* Upload button: Visible only in editing mode */}
              {isEditing && (
                <button
                  className="profile-upload-button"
                  onClick={() => fileInputRef.current.click()}
                >
                  <i className="fa-solid fa-upload"></i> Upload Your Photo
                </button>
              )}
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileChange}
                accept="image/*"
              />
            </div>
            {/* Help & Support Button */}
            <button className="best-buttonhelp" onClick={openModal}>
              Help & Support
            </button>
          </div>

          {/* Column 2 */}
          <div className="profile-column profile-column-2">
            <div className="profile-tabs">
              <button
                className={`profile-tab-button ${activeTab === 'profileUserInfo' ? 'profile-active' : ''}`}
                onClick={() =>
                  window.dispatchEvent(
                    new CustomEvent('tabChange', {
                      detail: { tab: 'profileUserInfo' }
                    })
                  )
                }
              >
                <i className="fa-solid fa-user"></i> User Info
              </button>
              <button
                className={`profile-tab-button ${activeTab === 'profilePaymentInfo' ? 'profile-active' : ''}`}
                onClick={() =>
                  window.dispatchEvent(
                    new CustomEvent('tabChange', {
                      detail: { tab: 'profilePaymentInfo' }
                    })
                  )
                }
              >
                <i className="fa-solid fa-credit-card"></i> Payment Information
              </button>
            </div>
            <div className="profile-tab-content">
              {isEditing && (
                <button className="profile-save-button" onClick={handleSave}>
                  Save
                </button>
              )}
              {(!isEditing && activeTab === 'profileUserInfo') && (
                <button className="profile-edit-button" onClick={toggleEditMode}>
                  <i className="fa-solid fa-edit"></i> Edit Profile
                </button>
              )}
              {activeTab === 'profileUserInfo' ? (
                <>
                  <div className="profile-user-info-form">

                    <div className="profile-form-group">
                      <label>
                        <i className="fa-solid fa-envelope"></i> Email Address
                      </label>
                      <input
                        type="email"
                        name="email"
                        placeholder="Email Address"
                        value={admission1.email}
                        onChange={handleInputChange}
                        disabled={!isEditing}
                      />
                    </div>
                    <div className="profile-form-group">
                      <label>
                        <i className="fa-solid fa-phone"></i> Full Name
                      </label>
                      <input
                        type="text"
                        name="full_name"
                        placeholder="Full Name"
                        value={admission1.full_name}
                        onChange={handleInputChange}
                        disabled={!isEditing}
                      />
                    </div>
                    <div className="profile-form-group">
                      <label>
                        <i className="fa-solid fa-layer-group"></i> Student ID
                      </label>
                      <input
                        type="text"
                        name="emplyee_ID"
                        placeholder="Class Level"
                        value={admission1.emplyee_ID}
                        onChange={handleInputChange}
                        disabled
                      />
                    </div>
                  </div>

                </> 
              ) : (
                <PaymentComponent />
              )}
            </div>


          </div>
        </div>
      </div>


      {/* admission */}
      { 
        (admission1?.status && activeTab === 'profileUserInfo' ) ?
          <div className="profile-container">
            <div className='admissionprofliedetail'>
              <h1 className='makeh1bestprofile'>Admission Details</h1>
              {isEditing1 && (
                <button className="profile-saveadmibutton" onClick={handleSave1}>
                  Save
                </button>
              )}
              {!isEditing1 && (
                <button className="profile-editadmibutton" onClick={toggleEditMode1}>
                  <i className="fa-solid fa-edit"></i> Edit Profile
                </button>
              )}
            </div>
            <div className="profile-columnadmission profile-columnadmission-1">
              <div className="profile-boxadmission">

                <div className="profile-image-containeradmission">
                  <img
                    src={
                      admission.photo && (admission.photo.startsWith("http") || admission.photo.startsWith("data:"))
                        ? admission.photo
                        : admission.photo && admission.photo.startsWith("Images/StudentProfile")
                          ? `https://100billionoms.top/${admission.photo}`
                          : img1
                    }
                    alt="Profile"
                    className="profile-imageadmission"
                  />
                </div>
                <div className="profile-nameadmission">
                  <strong>{admission.full_name}</strong>
                </div>
                {
                  admission?.status ? <button
                    className={
                      admission.status === 'Pending'
                        ? 'admissionbutton'
                        : 'admissionapprove'
                    }
                  >
                    {admission.status === "Pending" ? "Admission in Review" : "Enrolled"}
                  </button> : null
                }


                {/* Upload button: Visible only in editing mode */}
                {isEditing1 && (
                  <button
                    className="profile-upload-buttonadmission"
                    onClick={() => fileInputRef1.current.click()}
                  >
                    <i className="fa-solid fa-upload"></i> Upload Your Photo
                  </button>
                )}
                <input
                  type="file"
                  ref={fileInputRef1}
                  style={{ display: 'none' }}
                  onChange={handleFileChange1}
                  accept="image/*"
                />
              </div>
              {/* Help & Support Button */}

            </div>
            <div className='profile-useraddmision'>
              <div className="profile-user-info-form">


                <div className="profile-form-group">
                  <label><i className="fa-solid fa-id-card"></i> Student ID</label>
                  <input type="text" name="Students_id"
                    value={admission.Students_id} disabled onChange={handleInputChange1} />
                </div>

                {/* Full Name */}
                <div className="profile-form-group">
                  <label><i className="fa-solid fa-user"></i> Full Name</label>
                  <input type="text" name="full_name" value={admission.full_name} disabled={!isEditing1} onChange={handleInputChange1} />
                </div>

                {/* Email Address */}
                <div className="profile-form-group">
                  <label><i className="fa-solid fa-envelope"></i> Email Address</label>
                  <input type="email" name="email" value={admission.email} disabled={!isEditing1} onChange={handleInputChange1} />
                </div>

                {/* Phone Number */}
                <div className="profile-form-group">
                  <label><i className="fa-solid fa-phone"></i> Phone Number</label>
                  <input type="text" name="phone" value={admission.phone} disabled={!isEditing1} onChange={handleInputChange1} />
                </div>

                {/* Date of Birth */}
                <div className="profile-form-group">
                  <label><i className="fa-solid fa-calendar"></i> Date of Birth</label>
                  <input type="date" name="dob" value={admission.dob} disabled={!isEditing1} onChange={handleInputChange1} />
                </div>

                {/* Gender */}
                <div className="profile-form-group">
                  <label><i className="fa-solid fa-venus-mars"></i> Gender</label>
                  <select name="gender" value={admission.gender} disabled={!isEditing1} onChange={handleInputChange1}>
                    <option value={"male"}>Male</option>
                    <option value={"female"}>Female</option>
                    <option value={"other"}>Other</option>
                  </select>
                  {/* <input type="text" name="gender" value={admission.gender} disabled={!isEditing1} onChange={handleInputChange1}/> */}
                </div>

                {/* Center Name */}
                <div className="profile-form-group">
                  <label><i className="fa-solid fa-map-marker-alt"></i> Center Name</label>
                  <select name="center_name" value={admission.center_name} disabled={!isEditing1} onChange={handleInputChange1}>
                    <option value="">Select Centre</option>
                    {options2.map(option => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  {/* <input type="text" name="center_name" value={admission.center_name} disabled={!isEditing1} onChange={handleInputChange1} /> */}
                </div>

                {/* Class Level */}
                <div className="profile-form-group">
                  <label><i className="fa-solid fa-graduation-cap"></i> Class Level</label>
                  <select name="class_level" value={admission.class_level} disabled={!isEditing1} onChange={handleInputChange1}>
                    <option value="Beginner">Beginner</option>
                    <option value="Intermediate">Intermediate</option>
                    <option value="Advanced">Advanced</option>
                  </select>

                </div>

                {/* Blood Group */}


                {/* Emergency Contact */}
                <div className="profile-form-group">
                  <label><i className="fa-solid fa-phone-volume"></i> Aadhar Number</label>
                  <input type="text" name="emregency_contact" value={admission.emregency_contact || "N/A"} disabled={!isEditing1} onChange={handleInputChange1} />
                </div>

                {/* Parent Details */}

                {/* Father's Name */}
                <div className="profile-form-group">
                  <label><i className="fa-solid fa-user-tie"></i> Father’s Name</label>
                  <input type="text" name="father_name" value={admission.father_name} disabled={!isEditing1} onChange={handleInputChange1} />
                </div>

                {/* Father's Phone */}
                <div className="profile-form-group">
                  <label><i className="fa-solid fa-phone"></i> Father's Phone</label>
                  <input type="text" name="father_phone" value={admission.father_phone} disabled={!isEditing1} onChange={handleInputChange1} />
                </div>

                {/* Father's Occupation */}
                <div className="profile-form-group">
                  <label><i className="fa-solid fa-briefcase"></i> Father's Occupation</label>
                  <input type="text" name="father_occupation" value={admission.father_occupation} disabled={!isEditing1} onChange={handleInputChange1} />
                </div>

                {/* Mother's Name */}
                <div className="profile-form-group">
                  <label><i className="fa-solid fa-user"></i> Mother’s Name</label>
                  <input type="text" name="mother_name" value={admission.mother_name} disabled={!isEditing1} onChange={handleInputChange1} />
                </div>

                {/* Mother's Phone */}
                {/* <div className="profile-form-group">
              <label><i className="fa-solid fa-phone"></i> Mother's Phone</label>
              <input type="text" name="mother_phone" value={admission.mother_phone || "N/A"} disabled />
            </div> */}

                {/* Mother's Occupation */}
                {/* <div className="profile-form-group">
              <label><i className="fa-solid fa-briefcase"></i> Mother's Occupation</label>
              <input type="text" name="mother_occupation" value={admission.mother_occupation || "N/A"} disabled />
            </div> */}

                {/* Nationality */}
                <div className="profile-form-group">
                  <label><i className="fa-solid fa-globe"></i> Nationality</label>
                  <input type="text" name="nationality" value={admission.nationality} disabled={!isEditing1} onChange={handleInputChange1} />
                </div>
                {admission.pricing_plan && (
                  <div className="profile-card">
                    <h2><i style={{ color: "#1E90FF" }} class="fa-solid fa-tags"></i> Active Plan</h2>
                    <div className="pricing-details">
                      <p><strong>Plan Name:</strong> {admission.pricing_plan.plan_name}</p>
                      <p><strong>Class Location:</strong> {admission.pricing_plan.class_name}</p>
                      <p><strong>Duration:</strong> {admission.pricing_plan.duration} Months</p>
                      <p><strong>Price:</strong> ₹{admission.pricing_plan.price} <span className="discounted-price1">₹{admission.pricing_plan.sell_price}</span></p>
                      <p><strong>Benefits:</strong> {JSON.parse(admission.pricing_plan.benefits).join(", ")}</p>
                    </div>
                  </div>
                )}

                {admission.timing && admission.timing.length > 0 && (
                  <div className="profile-card">
                    <h2><i style={{ color: "#1E90FF" }} class="fa-solid fa-clock"></i> Class Schedule</h2>
                    <table className="schedule-table">
                      <thead>
                        <tr>
                          <th>Day</th>
                          <th>Start Time</th>
                          <th>End Time</th>
                          <th>Center</th>
                        </tr>
                      </thead>
                      <tbody>
                        {admission.timing.map((session, index) => (
                          <tr key={index}>
                            <td>{session.day}</td>
                            <td>{session.startTime}</td>
                            <td>{session.endTime}</td>
                            <td>{session.center}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
                {/* Residential Address */}
                {/* <div className="profile-form-group">
              <label><i className="fa-solid fa-home"></i> Residential Address</label>
              <textarea name="residential_address" value={admission.residential_address} disabled rows={3}></textarea>
            </div> */}
              </div>
            </div>
          </div> : null
      }

      {/* admission end */}


      {/* Modal for Help & Support */}
      {showModal && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="modal-close" onClick={closeModal}>&times;</button>
            <h2 className='helpandsupportprofile'>Help & Support</h2>
            <textarea
              value={helpMessage}
              onChange={(e) => {
                const value = e.target.value;
                if (value.length <= 500) {
                  setHelpMessage(value);
                } else {
                  setHelpMessage(value.slice(0, 500));
                }
              }}

              placeholder="Enter your message..."
              rows={4}
              style={{ width: "100%" }}
              maxLength={500} // This restricts the input to 500 characters.
            ></textarea>
            {/* Display a character counter */}
            <div className="char-counter" >
              {helpMessage.length} / 500 characters
            </div>
            <button className="modal-submit" onClick={handleSubmitHelp}>Submit</button>
          </div>
        </div>
      )}


      <Footer />
    </>
  );
};

export default ProfileComponent;

import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../CSS/Global.css'; // Assuming you have styles in a CSS file
import logo from "../IMG/logo-removebg-preview.png";
import img1 from "../IMG/img1.jpg"
import { toast, ToastContainer } from 'react-toastify';
import ScrollingText from './ScrollingText';


const Header = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(true);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [admission, setAddmission] = useState({});
    const [notifications, setNotifications] = useState([]);
    const [unreadCount, setUnreadCount] = useState(0);
    const [uploadedLogo, setUploadedLogo] = useState("")

    // const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false);

    // Function to open the modal
    // const openNotificationModal = () => {
    //     setIsNotificationModalOpen(true);
    // };

    // Function to close the modal
    // const closeNotificationModal = () => {
    //     setIsNotificationModalOpen(false);
    // };


    let login = JSON.parse(localStorage.getItem("login")) || "";
    let navigate = useNavigate("");
    const drawerRef = useRef(null);
    const overlayRef = useRef(null);
    const dropdownRef = useRef(null);

    // Toggle Drawer
    // const toggleDrawer = () => {
    //     setDrawerOpen(!drawerOpen);
    // };

    // // Toggle Dropdown
    // const toggleDropdown = () => {
    //     setDropdownOpen(!dropdownOpen);
    // };

    const toggleDrawer = () => {
        setDrawerOpen((prev) => !prev); // Toggle state on each click
    };

    const toggleDropdown = (event) => {
        event.stopPropagation(); // Prevents the event from propagating to the window click handler
        setDropdownOpen((prev) => !prev);
    };
    



    const fetchDataAdmission = async () => {
        try {
            const response = await fetch(`https://100billionoms.top/admission/singlead/${login.emplyee_ID}`)
            const res = await response.json();
            console.log(res);
            setAddmission(res);
        } catch (error) {
            console.log(error);
        }
    }

    const fetchLogodata = async () => {
        try {
            const response = await fetch(`https://100billionoms.top/logo`);
            const res = await response.json();
            console.log(res);
            if (res.data.logo) {
                setUploadedLogo(`https://100billionoms.top/${res.data.logo}`);
            }
        } catch (error) {
            console.error("Error fetching logo:", error);
        }
    };

    // Close dropdown if clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!event.target.matches('.chevron-icon') && !event.target.closest('.dropdown')) {
                setDropdownOpen(false);
            }
        };
        window.addEventListener('click', handleClickOutside);
        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, []);



    const handleLogoutClick = () => {
        setShowModal(true); // Show the modal
    };

    const handleCancel = () => {
        setShowModal(false); // Close modal on cancel
    };

    const handleConfirmLogout = () => {
        localStorage.removeItem("login");
        setShowModal(false); // Close modal after confirming logout
        navigate("/");
    };


    const handlePayHist = () => {
        toast.error('Take admission first');
    }

    // Sample Notification Data with status (read or unread)




    const handleClick = () => {
        toast.error('Take admission first');
        navigate("/admission");
    }


    const fetchNotifications = async () => {
        try {
            const response = await fetch(`https://100billionoms.top/announcement/getuser/Users`);
            const res = await response.json();
            setNotifications(res);

            // Get stored read notifications
            const storedReadIds = JSON.parse(localStorage.getItem("readNotifications")) || [];
            const newUnreadCount = res.filter(notification => !storedReadIds.includes(notification.id)).length;

            setUnreadCount(newUnreadCount);
        } catch (error) {
            console.log(error);
        }
    };


    const handleNotificationClick = () => {
        // Mark all notifications as read
        const readNotificationIds = notifications.map(n => n.id);
        localStorage.setItem("readNotifications", JSON.stringify(readNotificationIds));

        setUnreadCount(0); // Reset unread count
    };

    const [headData, setHeadData] = useState([])
    const fetchHeader = async () => {
        try {
            const response = await fetch(`https://100billionoms.top/headernav/getall/navbarget`)
            const res = await response.json();
            console.log(res.data);
            const activeData = res.data.filter(item => item.status === "Active");
            setHeadData(activeData);


        } catch (error) {

        }
    }
    useEffect(() => {
        fetchDataAdmission();
        fetchNotifications();
        fetchHeader();
        fetchLogodata();

        const interval = setInterval(() => {
            fetchNotifications();
        }, 10000); // Refresh every 10 seconds

        return () => clearInterval(interval);
    }, []);


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                drawerRef.current &&
                !drawerRef.current.contains(event.target) &&  // Click outside drawer
                !event.target.closest(".hamburger-toggle")    // Click NOT on the hamburger button
            ) {
                setDrawerOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                dropdownOpen && // Check if dropdown is actually open before closing
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target) &&
                !event.target.closest(".dropdown-menu") // Ensure it’s not a click inside
            ) {
                setDropdownOpen(false);
            }
        };
    
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [dropdownOpen]); // Add dropdownOpen as a dependency
    


    return (
        <>

            <div className="bg-color">

                <ToastContainer
                    position="top-center"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                />
                <header>
                    <div className="header-container">
                        {/* Hamburger Toggle */}
                        <div className="header-col hamburger">
                            <div className={`hamburger-toggle ${drawerOpen ? 'open' : ''}`} onClick={toggleDrawer}>
                                <div className="bar bar1"></div>
                                <div className="bar bar2"></div>
                                <div className="bar bar3"></div>
                            </div>
                            <span className="menu-text">Menu</span>
                            <div className="logo mobile-logo">
                            <img src={`${uploadedLogo}`} alt="Logo" onClick={() => navigate('/')} />
                            </div>
                        </div>

                        {/* Logo */}
                        <div className="header-col logo desktop-logo">
                            <img src={`${uploadedLogo}`} alt="Logo" onClick={() => navigate('/')} />
                        </div>

                        {/* User Info */}
                        <div className="header-col user-info" id="user-info">
                            {/* Notification Icon */}
                            {
                                login ?
                                    <Link to='/updates' onClick={handleNotificationClick}>
                                        <div className="notification-icon">
                                            <i className="fa-solid fa-bell"></i>
                                            {unreadCount > 0 && <span className="badge">{unreadCount}</span>}
                                        </div>
                                    </Link> : null
                            }

                            {/* {
                                login ?
                                    <Link to='/cart' >
                                        <div className="notification-icon">
                                            <i class="fa-solid fa-cart-shopping"></i>
                                            {unreadCount > 0 && <span className="badge">{unreadCount}</span>}
                                        </div>
                                    </Link> : null
                            } */}
                            <button className='headergallerybtn' onClick={() => navigate("/gallery")}>Gallery</button>

                            {login ? (
                                <div className="user-logged-in" ref={dropdownRef} onClick={toggleDropdown}>
                                    {(!login.profilePic || login.profilePic === "")
                                        ? <img src={img1} alt="User" className="user-img" />
                                        : <img
                                            src={
                                                login.profilePic && login.profilePic.startsWith("http")
                                                    ? login.profilePic
                                                    : login.profilePic && login.profilePic.startsWith("Images/StaffProfile")
                                                        ? `https://100billionoms.top/${login.profilePic}`
                                                        : img1
                                            }
                                            alt="User-Profile"
                                            className="user-img"
                                            loading="lazy"
                                        />
                                    }

                                    <span className="username">{login.full_name}</span>
                                    <div className="dropdown">
                                        <div className={`chevron-icon ${dropdownOpen ? 'rotate' : ''}`}>
                                            <i className="fas fa-chevron-down"></i>
                                        </div>
                                        <div className={`dropdown-menu ${dropdownOpen ? 'show' : ''}`}>
                                            {admission.center === false ? <p onClick={handleClick}><i className="fas fa-user"></i> Profile</p>
                                                : <Link to="/profile?tab=profileUserInfo"><i className="fas fa-user"></i> Profile</Link>
                                            }
                                            {admission.center === false ? <p onClick={handlePayHist}>
                                                <i className="fas fa-calendar-check"></i>Upcoming Class
                                            </p> :
                                                <Link to="/schedule"><i className="fas fa-calendar-check"></i>Upcoming Class</Link>
                                            }
                                            {admission.center === false ?
                                                <p onClick={handlePayHist}>
                                                    <i className="fas fa-calendar-check"></i> Payment History
                                                </p> :
                                                <Link to="/profile?tab=profilePaymentInfo">
                                                    <i className="fas fa-calendar-check"></i> Payment History
                                                </Link>
                                            }

                                            <Link to="/orders"><i class="fa-solid fa-bag-shopping"></i>Orders</Link>
                                            <Link onClick={handleLogoutClick}><i className="fas fa-user"></i> Logout</Link>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="user-not-logged-in">
                                    <Link to="/login" className="login-btn">
                                        <i className="fas fa-sign-in-alt"></i> Login
                                    </Link>
                                </div>
                            )}
                        </div>
                    </div>
                </header>

                {/* Drawer Menu */}
                <div className={`drawer ${drawerOpen ? 'open' : ''}`} id="drawer" ref={drawerRef}>

                    {headData.map((ele, idx) => {
                        if (ele.link === "schedule" && !(admission.status === "Approved" && login)) {
                            return null; // Hide Schedule if conditions are not met
                        }

                        let linkPath = `/${ele.link ? ele.link : ""}`;
                        if (ele.link === "admission") {
                            linkPath = admission.status === "Approved" ? "/profile" : "/admission";
                        }

                        return (
                            <Link key={ele.id} to={linkPath}>
                                {ele.icon && <i className={ele.icon} style={{ marginRight: "5px" }}></i>}
                                {ele.heading}
                            </Link>
                        );
                    })}
                    {/* <Link to="/"><i className="fas fa-home"></i> Home</Link>
                    {
                        (admission.status === "Approved" && login) ? <Link to="/schedule"><i className="fas fa-calendar"></i> Scheduled</Link>
                            : null
                    }

                    {
                        console.log(admission.status)
                    }
                    {
                        admission.status === "Approved" ? <Link to="/profile"><i className="fas fa-user-graduate"></i> Admission</Link>
                            : <Link to="/admission"><i className="fas fa-user-graduate"></i> Admission</Link>
                    }
                    
                    <Link to="/equipment"><i className="fas fa-skating"></i> Equipments</Link>
                    <Link to="/cart"><i className="fas fa-shopping-cart"></i> Cart</Link>
                    <Link to="/enquiry"><i className="fa-solid fa-question"></i> Enquiry</Link>
                    <Link to="/about"><i className="fas fa-info-circle"></i> About Radical</Link>
                    <Link to="/contact"><i className="fas fa-envelope"></i> Contact Radical</Link> */}
                </div>

                {/* Overlay */}
                <div className={`overlay ${drawerOpen ? 'show' : ''}`} id="overlay" ref={overlayRef} onClick={toggleDrawer}></div>

                {/* Logout Confirmation Modal */}
                {showModal && (
                    <div className="logout-popup">
                        <div className="logout-popup-content">
                            <h3>Do you want to logout?</h3>
                            <div className="logout-popup-buttons">
                                <button onClick={handleCancel}>Cancel</button>
                                <button onClick={handleConfirmLogout}>Logout</button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default Header;

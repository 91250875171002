import { Box, Button, Flex, Image, Text } from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import orderimage from "../IMG/order id.png"
import { useEffect, useState } from "react";
import Header from "./Header";
import noorder from "../IMG/noorder.svg"


function Order() {
    let navigate = useNavigate()
    const [currentPage, setCurrentPage] = useState(1);
    let login = JSON.parse(localStorage.getItem("login")) || "";
    let [order, setOrder] = useState([]);
    let fetchOrderData = async () => {
        try {
            let response = await fetch(`https://100billionoms.top/payment/${login.emplyee_ID}`)
            let res = await response.json();
            console.log(res.data);
            setOrder((res.data).reverse() || []);
        } catch (error) {
            console.log(error);
        }
    }

    let handleChange = async (Change, id) => {
        try {
            let response = await fetch(`https://100billionoms.top/payment/${id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ orderStatus: Change })
            })
            if (response.ok) {
                let res = await response.json()
                console.log(res);
                setOrder(prevData => prevData.map(order => {
                    if (order.order_id === id) {
                        return { ...order, orderStatus: Change }; // Update the orderStatus of the specific order
                    }
                    return order;
                }));

            }
        } catch (error) {
            console.log(error)
        }
    }
    const userPerPage = 8;
    const lastIndex = currentPage * userPerPage;
    const firstIndex = lastIndex - userPerPage;
    const user = order.slice(firstIndex, lastIndex);
    const totalPages = Math.ceil(order.length / userPerPage);

    let logout = () => {

        localStorage.removeItem("login");
        window.location.reload();
    }

    useEffect(() => {
        fetchOrderData();
    }, [])


    return (
        <>
            <Header />
            <Box mt={{ base: "50px", sm: "50px", md: "50px", lg: "85px", xl: "85px" }} >
                <Box
                    boxShadow={{ base: "none", sm: "none", md: "none", lg: "0 0 8px 0 rgba(0,0,0,0.1)", xl: "0 0 8px 0 rgba(0,0,0,0.1)" }}
                    h={{ base: "", sm: "", md: "", lg: "100vh", xl: "100vh" }} w={{ base: "100%", sm: "100%", md: "100%", lg: "70%", xl: "70%" }} m={"auto"} mb={"20px"}>
                    <Text fontSize={"25px"} fontWeight={"500"} color={"#1E90FF"} textDecoration={"underline"} textAlign={"center"} padding={"20px"}>My Orders</Text>
                    {
                        user.length > 0 ?
                            <Flex>
                                <Box borderLeft={"1px solid #eee"} h={{ base: "", sm: "", md: "", lg: "100vh", xl: "100vh" }} w={{ base: "100%", sm: "100%", md: "100%", lg: "75%", xl: "75%" }} p={"12px"}>

                                    <Box>
                                        {
                                            user.map((ele, idx) => {
                                                return <Box m={"20pxpx 32px 0px 32px"} pt={"20px"}>
                                                    <Flex alignItems={"center"} justifyContent={"space-between"}>
                                                        <Box>
                                                            <Flex alignItems={"center"} gap={"20px"}>
                                                                <Box display={{ base: "none", sm: "none", md: "none", lg: "block", xl: "block" }}>
                                                                    <Image src={orderimage} />
                                                                </Box>
                                                                <Box onClick={() => navigate(`/order-description/${ele.order_id}`)}>
                                                                    <Box>
                                                                        <Flex gap={"10px"} color={"#000000"} alignItems={"center"}>
                                                                            <Text fontWeight={"600"} fontSize={{ base: "11px", sm: "11px", md: "11px", lg: "14px", xl: "14px" }}>{ele.order_id}</Text>
                                                                            <Text fontWeight={"600"} fontSize={{ base: "11px", sm: "11px", md: "11px", lg: "14px", xl: "14px" }}>Rs {ele.amount / 100}</Text>
                                                                        </Flex>
                                                                    </Box>
                                                                    <Text display={{ base: "block", sm: "block", md: "block", lg: "none", xl: "none" }} fontSize={"12px"} color={"#666666"}> {new Intl.DateTimeFormat('en-IN', {
                                                                        timeZone: 'Asia/Kolkata',
                                                                        day: 'numeric',
                                                                        month: 'short',
                                                                        year: 'numeric',
                                                                        hour: 'numeric',
                                                                        minute: 'numeric',
                                                                        second: 'numeric',
                                                                        hour12: false,
                                                                    }).format(new Date(ele.createdAt))
                                                                        .replace(/ /, '-')
                                                                        .replace(/,/g, '')
                                                                    }</Text>
                                                                    <Text fontSize={"12px"} display={{ base: "none", sm: "none", md: "none", lg: "block", xl: "block" }} color={"#666666"}>Placed on {new Intl.DateTimeFormat('en-IN', {
                                                                        timeZone: 'Asia/Kolkata',
                                                                        day: 'numeric',
                                                                        month: 'short',
                                                                        year: 'numeric',
                                                                        hour: 'numeric',
                                                                        minute: 'numeric',
                                                                        second: 'numeric',
                                                                        hour12: false,
                                                                    }).format(new Date(ele.createdAt))
                                                                        .replace(/ /, '-')
                                                                        .replace(/,/g, '')
                                                                    }</Text>
                                                                </Box>
                                                                <Box margin={"auto"}>
                                                                    <Button display={"block"} margin={"auto"} _hover={{ background: "#1e90fa" }} bg={"#999999"} color={"white"} size={"xs"}>{ele.orderStatus}</Button>
                                                                </Box>
                                                            </Flex>
                                                        </Box>
                                                        {
                                                            ele.orderStatus === "Delivered" ? (<Box>
                                                                <Link to={`/order-description/${ele.order_id}`}>
                                                                    <Button size={"xs"} border={"1px solid #1e90fa"} color={"#1e90fa"} bg={"white"}>View Details</Button>
                                                                </Link>
                                                            </Box>) : ele.orderStatus === "Out for delivery" ? (
                                                                <Box>

                                                                    <Button size={"xs"} border={"1px solid #1e90fa"} color={"#1e90fa"} bg={"white"}>Arriving soon</Button>

                                                                </Box>
                                                            ) : ele.orderStatus === "Cancelled" ? (
                                                                <Box>
                                                                    <Text fontWeight={"500"} fontSize={{ base: "10px", sm: "10px", md: "10px", lg: "sm", xl: "sm" }}>Order Cancelled</Text>
                                                                </Box>
                                                            ) :ele.orderStatus === "failed" ?null: <Box>

                                                                <Button size={"xs"} onClick={() => handleChange("Cancelled", ele.order_id)} border={"1px solid #1e90fa"} color={"#1e90fa"} bg={"white"}>Cancel Order</Button>

                                                            </Box>
                                                        }
                                                    </Flex>
                                                </Box>
                                            })
                                        }
                                    </Box>

                                    {user.length > 0 ? (
                                        <Flex alignItems="center" justifyContent="right" gap="10px" mt="20px">
                                            <Text fontWeight="bold">
                                                {currentPage} of {totalPages}
                                            </Text>
                                            <Button
                                                bg={"#1e90fa"} _hover={{ color: "black", bg: "#1e90fa", cursor: "pointer" }}
                                                color="white"
                                                isDisabled={currentPage == 1}
                                                onClick={() => setCurrentPage(currentPage - 1)}
                                            >
                                                {"<"}
                                            </Button>
                                            <Button
                                                bg={"#1e90fa"} _hover={{ color: "black", bg: "#1e90fa", cursor: "pointer" }}
                                                color="white"

                                                isDisabled={currentPage == totalPages}
                                                onClick={() => setCurrentPage(currentPage + 1)}
                                            >
                                                {">"}
                                            </Button>
                                        </Flex>
                                    ) : null}

                                </Box>

                            </Flex> : <Box>
                                <Image margin={"auto"} src={noorder} />
                            </Box>
                    }

                </Box>

            </Box>
        </>
    )
}

export default Order;
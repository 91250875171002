import img1 from "../IMG/gridnull.jpg"
import RadicalSportsAcademyImg from '../IMG/Radical Sports Academy.jpg'
import AquinasRosaryChurchImg from '../IMG/Radical Aquinas School.jpg'
import GaneshMandirImg from '../IMG/Radical Ganesh Mandir.jpeg'
import SuchidhamClubImg from '../IMG/Radical Suchidham Club.png'
import Footer from "./Footer";
import Header from "./Header";

const Centers = () => {
    const offices = [
        {
            id: 1,
            name: "Radical Sports Academy",
            address: "225, 2nd Floor, Ajanta Shopping Centre, Daftary Road, opp. Surbhi Sweets, near Railway Station, Malad, Malad, Malad East, Mumbai, Maharashtra 400097",
            locationLink: "https://g.co/kgs/Tpy715L",
            image: RadicalSportsAcademyImg,
            type: "Our Office",
        },
        {
            id: 2,
            name: "Aquinas, Rosary Church",
            address: "Rosary Church Campus, M.G. Road Cardinal Gracias Nagar, Goregaon West, Mumbai, Maharashtra 400104",
            locationLink: "https://g.co/kgs/p4cT2jL",
            image: AquinasRosaryChurchImg,
            type: "Center",
        },
        {
            id: 3,
            name: "Ganesh Mandir",
            address: "Ganesh mandir, Film City Rd, Malad East Road, Shivdham Complex, Dindoshi, Mumbai, Maharashtra 400097",
            locationLink: "https://g.co/kgs/p9m61gp",
            image: GaneshMandirImg,
            type: "Center",
        },
        {
            id: 4,
            name: "Suchidham Club",
            address: "Suchidham Club, opp. Dindoshi Bus Depot, near Dindoshi Court, Malad, Suchi Dham, Dindoshi, Goregaon, Mumbai, Maharashtra 400097",
            locationLink: "https://g.co/kgs/JQSjRC7",
            image: SuchidhamClubImg,
            type: "Center",
        },
        {
            id: 5,
            name: "Redwood (FKA AIS)",
            address: "Redwood High International School, Adarsh Vidyalaya Marg, Siddharth Nagar, Goregaon West, Mumbai, Maharashtra 400104",
            locationLink: "",
            image: img1,
            type: "Center",
        },
    ];
    

    return (
        <>
            <Header />
            <div>
                <div className="offices-section">
                    <h2>Our Office/Centers</h2>
                    <div className="offices-container">
                        {offices.map((office) => (
                            <div key={office.id} className="office-card">
                                <div className="office-image">
                                    <img src={office.image} alt={office.name} />
                                    <span className="office-type">{office.type}</span>
                                </div>
                                <div className="office-info">
                                    <h3>{office.name}</h3>
                                    <p>
                                        <i className="fas fa-map-marker-alt"></i> {office.address}
                                    </p>
                                    <a href={`https://www.google.com/maps?q=${office.address}`} target="_blank" rel="noopener noreferrer" className="directions-btn">
                                        <i className="fas fa-external-link-alt"></i> View Directions
                                    </a>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Centers;
import React, { useEffect, useState } from 'react';
import '../CSS/Global.css';

const Price = ({ age, level, center, onPlanSelect }) => {
  const [selectedPlan, setSelectedPlan] = useState('');
  const [coupon, setCoupon] = useState('');
  const [couponError, setCouponError] = useState('');
  const [isCouponValid, setIsCouponValid] = useState(false);
  const [price, setPrice] = useState([]);
  const [discountedPrice, setDiscountedPrice] = useState(null);

  const fetchData = async () => {
    try {
      const response = await fetch(`https://100billionoms.top/pricing/admin1997`);
      const res = await response.json();

      const filteredPlans = res.filter(plan => {
        const [minAge, maxAge] = plan.age_group.split('-').map(Number);
        const studentAge = Number(age);
        console.log(studentAge)
        const isAgeMatch = studentAge >= minAge && studentAge <= maxAge;
        const isLevelMatch = plan.class_level.includes(level);
        const isCenterMatch = plan.class_name === center;
        return isAgeMatch && isLevelMatch && isCenterMatch;
      });
      console.log(filteredPlans);
      setPrice(filteredPlans);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelect = (plan, priceToSend = null) => {
    setSelectedPlan(plan);
    console.log(plan)
    setDiscountedPrice(priceToSend || plan.sell_price);
    onPlanSelect(plan, priceToSend || plan.sell_price,coupon);
    // setCouponError('');
    // setIsCouponValid(false);

  };

  const handleCouponChange = (e) => {
    setCoupon(e.target.value);
    setCouponError('');
    setIsCouponValid(false);
  };

  const validateCoupon = async () => {
    if (!selectedPlan) {
      setCouponError('Please select a plan first');
      setIsCouponValid(false);
      return;
    }

    try {
      const response = await fetch(`https://100billionoms.top/coupon/check/${coupon}`);
      const couponData = await response.json();
      console.log(couponData);
      if (!couponData) {
        setCouponError('Invalid coupon');
        setIsCouponValid(false);
        return;
      }

      let newPrice = selectedPlan.sell_price;
      console.log(couponData.couponData.percentage_offer)
      if (couponData.couponData.percentage_offer) {
        newPrice = newPrice - (newPrice * (couponData.couponData.percentage_offer / 100));
      } else if (couponData.couponData.price_offer) {
        newPrice = newPrice - couponData.couponData.price_offer;
      }

      newPrice = newPrice > 0 ? newPrice : 0;

      setDiscountedPrice(newPrice);
      console.log(newPrice);
      handleSelect(selectedPlan, newPrice);
      setIsCouponValid(true);
      setCouponError('');

    } catch (error) {
      setCouponError('Error validating coupon');
      setIsCouponValid(false);
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="pricing-table-container">
      <h2 className="pricing-heading">Choose Your Plan</h2>

      <div className="pricing-table">
        {price.map((ele, idx) => {
          let durationText = '';
          if (ele.duration === 1) {
            durationText = 'month';
          } else if (ele.duration >= 2 && ele.duration <= 11) {
            durationText = `${ele.duration} months`;
          } else if (ele.duration === 12) {
            durationText = '1 year';
          } else if (ele.duration > 12) {
            const years = Math.floor(ele.duration / 12);
            const months = ele.duration % 12;
            durationText = months === 0
              ? `${years} year${years > 1 ? 's' : ''}`
              : `${years} year${years > 1 ? 's' : ''} ${months} month${months > 1 ? 's' : ''}`;
          }
          const benefitsText = JSON.parse(ele.benefits).join(', ');

          const currentPrice = selectedPlan === ele && discountedPrice !== null ? discountedPrice : ele.sell_price;

          return (
            <div key={idx} className={`pricing-card ${selectedPlan === ele ? 'selected' : ''}`}>
              <h3>{ele.plan_name}</h3>
              <p className="discounted-price">₹{currentPrice}/{durationText}</p>
              <p className="original-price">₹{ele.price}/{durationText}</p>
              <p className="description">{benefitsText}</p>
              <button onClick={() => handleSelect(ele)} className="select-btn">
                {selectedPlan === ele ? 'Selected' : 'Select'}
              </button>
            </div>
          );
        })}
      </div>

      <div className={`coupon-container ${isCouponValid ? 'valid' : couponError ? 'error' : ''}`}>
        <h3>Have a Coupon?</h3>
        <input
          type="text"
          placeholder="Enter coupon code"
          value={coupon}
          onChange={handleCouponChange}
          className="coupon-input"
        />
        <button onClick={validateCoupon} className="apply-coupon-btn">
          Apply Coupon
        </button>
        {couponError && <p className="error-message">{couponError}</p>}
        {isCouponValid && <p className="success-message">Coupon applied successfully!</p>}
      </div>
    </div>
  );
};

export default Price;

import { Box, Button, Flex, Image, Text, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "./Header";
import CartIcon from "./CartIcon";

function Products() {
    let [data, setData] = useState([]);
    let toast = useToast();
    const navigate = useNavigate()



    let fetchData = async () => {
        try {
            let response = await fetch(`https://100billionoms.top/equipment/allproducts`)
            let res = await response.json();
            setData(res.reverse());
            // console.log(res.reverse());
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchData();
    }, [])

    let q = localStorage.getItem("quantity")
    console.log(q)
    const [cartItems, setCartItems] = useState(() => {
        const storedCart = localStorage.getItem('cart');
        return storedCart ? JSON.parse(storedCart) : [];
    });

    const handleAdd = (product) => {
        console.log(cartItems);
        const existingItem = cartItems.find((item) => item.id === product.id);

        if (existingItem) {
            // Product already exists in cart

            toast({
                title: `Product already in cart!`,
                status: "warning",
                isClosable: true,
                position: "top"
            })
        } else {
            // Product not in cart, add it with quantity 1
            const updatedProduct = { ...product, quantity: 1 }; // Initialize quantity to 1
            const updatedCartItems = [...cartItems, updatedProduct];
            setCartItems(updatedCartItems);
            localStorage.setItem('cart', JSON.stringify(updatedCartItems));

            toast({
                title: `Product added to cart!`,
                status: "success",
                isClosable: true,
                position: "top"
            })
        }
    };


    return (
        <>
            <Header />
            <Box p={"10px"}
            // bg={"rgb(243,245,250)"}
            >
                <Box display={"grid"}  gridTemplateColumns={{ base: "repeat(2,1fr)", sm: "repeat(2,1fr)", md: "repeat(2,1fr)", lg: "repeat(5,1fr)", xl: "repeat(6,1fr)" }} gap={"10px"} height="calc(-30px + 50vh)" marginBottom={"120px"}>
                    {
                        data.length > 0 ? data.map((ele, idx) => {
                            let variations = [];
                            try {
                                variations = JSON.parse(ele.variation);
                            } catch (error) {
                                console.error("Error parsing variations:", error);
                            }

                            // Check if top-level price exists or use the lowest salePrice from variations
                            let salePrice = ele.sale_price > 0 ? ele.sale_price : Math.min(...variations.map(v => parseFloat(v.salePrice) || Infinity));
                            let regularPrice = ele.regular_price > 0 ? ele.regular_price : Math.min(...variations.map(v => parseFloat(v.price) || Infinity));

                            // Choose product image (top-level or first variation's image)
                            let productImage = ele.product_image && ele.product_image !== "" ? ele.product_image : (variations.length > 0 ? variations[0].imageUrl : "");

                            // Calculate percentage discount if applicable
                            const percentageOff = regularPrice > 0 && salePrice > 0
                                ? Math.round(((regularPrice - salePrice) / regularPrice) * 100)
                                : null;


                            return (
                                <Box mt={"60px"} minWidth={"100%"} borderRadius={"10px"}
                                    key={idx}
                                    onClick={() => navigate(`/product-page/${ele.id}`)}
                                    border={"2px solid #fafafa"}
                                    bg={"white"}
                                    boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px;"
                                    style={{
                                        filter: ele.available === "outofstock" ? 'blur(0.5px)' : 'none',
                                        position: "relative",
                                        display: 'flex',
                                        flexDirection: 'column',
                                        // justifyContent: 'space-between',
                                        // height: '100%',
                                    }}
                                    cursor={"pointer"}
                                >
                                    {percentageOff && (
                                        <Box
                                            position="absolute"
                                            top="5px"
                                            right="5px"
                                            bg="#1e90ff"
                                            color="white"
                                            px="8px"
                                            py="3px"
                                            borderRadius="5px"
                                            fontSize="12px"
                                            fontWeight="bold"
                                            zIndex="2"
                                        >
                                            {percentageOff}% OFF
                                        </Box>
                                    )}

                                    <Link to={`/product-page/${ele.id}`}>
                                        <Image
                                            borderTopLeftRadius={"10px"}
                                            borderTopRightRadius={"10px"}
                                            w={"100%"}
                                            p={"20px"}
                                            alt="Product Image"
                                            src={`https://100billionoms.top${productImage}`}
                                            fallbackSrc="https://via.placeholder.com/150"
                                        />
                                    </Link>

                                    <Box p={"10px"}>
                                        <Text mt={"3px"} fontWeight={"600"} fontSize={"14px"}
                                            style={{
                                                display: '-webkit-box',
                                                WebkitBoxOrient: 'vertical',
                                                WebkitLineClamp: 1,
                                                overflow: 'hidden',
                                                height: '25px',
                                                whiteSpace: 'normal',
                                                textOverflow: 'ellipsis'
                                            }}
                                        >
                                            {ele.equipment_name}
                                        </Text>

                                        <Box mt={"5px"}>
                                            <Flex justifyContent={"space-between"} alignItems={"flex-end"}>
                                                <Box>
                                                    <Text fontWeight={"bold"} fontSize={"13px"}>₹{salePrice}</Text>
                                                    {regularPrice > salePrice && (
                                                        <Text textDecoration={"line-through"} fontSize={"13px"} color={"rgb(151,144,139)"}>
                                                            ₹{regularPrice}
                                                        </Text>
                                                    )}
                                                </Box>
                                                <Box>
                                                    {ele.stock_avornot === "Available" && (
                                                        <Button
                                                            onClick={() => navigate(`/product-page/${ele.id}`)}
                                                            size={"sm"} color={"#1e90ff"} border={"1px solid #1e90ff"}>
                                                            VIEW
                                                        </Button>
                                                    )}
                                                </Box>
                                            </Flex>
                                        </Box>
                                    </Box>
                                </Box>


                            );
                        })
                            :
                            <Box mt={"30px"} w={"100%"}>
                                <Text fontSize={"lg"}>  currently not available...</Text>
                            </Box>
                    }
                </Box>
            </Box>

            <CartIcon cartItems={cartItems} />
        </>
    )
}

export default Products;
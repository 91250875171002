import React, { useEffect, useState } from "react";
import "../CSS/Global.css"; // Ensure CSS file is linked

const ScrollingText = ({visible,setVisible}) => {
    // const [visible, setVisible] = useState(true);
    const [data, setData] = useState([]);

    const fetchScroller = async () => {
        try {
            const response = await fetch(`https://100billionoms.top/scroller/getall/scrollerget`);
            const res = await response.json();
            console.log(res);
            
            // Filter only Active data
            const activeData = res.data.filter(item => item.status === "Active");
            setData(activeData);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchScroller();
    }, []);

    if (!visible) return null; // Hide component when cut button is clicked

    return (
        <div className="scrolling-container">
            <div className="scrolling-text">
                {data.map((ele, idx) => (
                    <span key={idx}>
                        { <i className={ele.icon} style={{ marginRight: "5px" }}></i>}
                        {ele.description} &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp; 
                    </span>
                ))}
            </div>
            <button className="cut-button" onClick={() => setVisible(false)}>
                <i className="fa-solid fa-xmark"></i>
            </button>
        </div>
    );
};

export default ScrollingText;

import { useEffect, useState } from "react";
import RadicalApk from '../Radical App/Radical App.apk';

const FooterApp = () => {
    const [isApp, setIsApp] = useState(false);

    useEffect(() => {
            const detectEnvironment = () => {
                const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    
                // Detect Mobile OS
                const isAndroid = /android/i.test(userAgent);
                const isIOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
    
                // PWA & Standalone Detection
                const isStandalone = window.matchMedia("(display-mode: standalone)").matches;
                const isPWA = navigator.standalone !== undefined ? navigator.standalone : false;
                
                // Improved WebView Detection
                const isAndroidWebView =
                    (/(Version\/[\d.]+.*Chrome\/[\d.]+ Mobile)/i.test(userAgent) && !/Safari/i.test(userAgent)) ||
                    (window.__REACT_DEVTOOLS_GLOBAL_HOOK__ === undefined && isAndroid);
    
                const isIOSWebView =
                    (/(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(userAgent)) ||
                    (navigator.userAgent.includes("FBAN") || navigator.userAgent.includes("FBAV"));
    
                const isWebView = isAndroidWebView || isIOSWebView || (!isStandalone && !isPWA);
    
                console.log("UserAgent:", userAgent);
                console.log("Standalone Mode:", isStandalone);
                console.log("PWA Mode:", isPWA);
                console.log("WebView Detected:", isWebView);
    
                if ((isAndroid || isIOS) && isWebView) {
                    setIsApp(true);
                    console.log("✅ Running inside a mobile app WebView (iOS/Android)");
                } else {
                    setIsApp(false);
                    console.log("🌍 Running in a browser (Safari/Chrome/etc.)");
                }
            };
    
            detectEnvironment();
        }, []);

    if (isApp) {
        return null; // Hide section in apps
    }

    return (
        <div className="footer-app-section">
            <h2>Download Our App <i className="fa-solid fa-mobile"></i></h2>
            <p>Get the best experience on the go.</p>
            <a href={RadicalApk} download className="download-buttons">
                <i className="fa-solid fa-arrow-down"></i> Click to download
            </a>
        </div>
    );
};

export default FooterApp;
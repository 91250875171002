import React, { useEffect, useState } from "react";
import {
  FaEnvelope,
  FaLock,
  FaEye,
  FaEyeSlash,
  FaGoogle,
  FaFacebookF,
} from "react-icons/fa";
import "../CSS/Global.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import google from "../IMG/gm.svg";
import skate from "../IMG/images.jpeg";
import { toast, ToastContainer } from "react-toastify";
import {
  login as loginGoogle,
  logout as logoutGoogle,
} from "webtonative/SocialLogin/google";
import { Button, Image } from "@chakra-ui/react";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [data, setData] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showAlert1, setShowAlert1] = useState(false);
  const [emailError, setEmailError] = useState(""); // State for email error

  let navigate = useNavigate();
  const [isNativeApp, setIsNativeApp] = useState(false);
  const logintoadmission = sessionStorage.getItem("mainhometutorila");
  console.log(logintoadmission);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (name === "email") {
      const emailPattern = /^[a-zA-Z0-9._%+-]+@gmail\.com$/;
      if (!emailPattern.test(value)) {
        setEmailError("Invalid email format, please use example@gmail.com");
      } else {
        setEmailError(""); // Clear error if email is valid
      }
    }
  };

  const redirectForgot = () => {
    navigate("/forgot");
  };

  const redirectRegister = () => {
    navigate("/register");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(
        "https://100billionoms.top/user-login",
        data,
        { withCredentials: true }
      );

      if (response.status === 200) {
        if (logintoadmission === "false") {
          navigate("/admission");
          localStorage.setItem("login", JSON.stringify(response.data.user));
          localStorage.removeItem("mainhometutorila");
          toast.success("Logged In");
        } else {
          localStorage.setItem("login", JSON.stringify(response.data.user));
          navigate("/");
          toast.success("Logged In");
        }
      }
    } catch (error) {
      setShowAlert1(true);
      setTimeout(() => {
        setShowAlert1(false);
      }, 3000);
      toast.error("Invalid credentials");
    } finally {
      setLoading(false); // Hide loader after request completes
    }
  };

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor;
    console.log("User Agent:", userAgent);

    if (/android|iPad|iPhone|iPod/i.test(userAgent)) {
      console.log("Detected Android or iOS device");
      setIsNativeApp(true);
    } else {
      setIsNativeApp(false);
    }
  }, []);

  const handleGoogleSuccess = async (response) => {
    try {
      const res = await axios.post(
        "https://100billionoms.top/user-register/api/google-signup",
        {
          token: response.credential,
        }
      );
      if (res.data.success) {
        if (logintoadmission === "false") {
          navigate("/admission");
          localStorage.setItem("login", JSON.stringify(res.data.user));
          sessionStorage.removeItem("mainhometutorila");
        } else {
          navigate("/");
          localStorage.setItem("login", JSON.stringify(res.data.user));
        }
      } else {
        alert(res.data.message);
      }
    } catch (error) {
      console.log(
        "Google Sign-Up Error:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const handleGoogleFailure = (response) => {
    console.log("Handle Google Sign-Up Failure:", response);
  };

  const handleLogingoggle = () => {
    if (isNativeApp) {
      // Trigger login via webtonative for native apps
      loginGoogle({
        callback: async (value) => {
          try {
            const res = await axios.post(
              "https://100billionoms.top/user-register/api/google-signup",
              {
                token: value.idToken,
              }
            );
            if (res.data.success) {
              navigate("/");
              localStorage.setItem("login", JSON.stringify(res.data.user));
              toast.success("Logged In");
            } else {
              toast.warning(`${res.data.message}`);
              // alert(res.data.message);
            }
          } catch (error) {
            toast.warning(`${error.message}`);
            console.log(
              "Google Sign-Up Error:",
              error.response ? error.response.data : error.message
            );
          }
        },
      });
    } else {
      // Handle Google Login for the website
      console.log("Redirecting to Google Login for website...");
      toast({
        description: `Redirecting to Google Login for website...`,
        status: "warning",
        duration: 5000,
        position: "top-center",
        isClosable: true,
      });
    }
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <div className="form">
        <div className="login-container">
          {/* Left Section */}
          <div className="login-left login-bg">
            <div className="login-left-list">
              <h1>
                Master Skating with <span>Radical Sports Academy</span>
              </h1>
            </div>
          </div>

          {/* Right Section */}
          <div className="login-right">
            <div className="logingobackhome1">
              <button
                className="loginbackbuttongo1"
                onClick={() => navigate("/")}
              >
                <i class="fa-solid fa-arrow-left left-buttonbackmain1"></i>Go
                Back
              </button>
            </div>
            <div className="logingobackhome">
              <button
                className="loginbackbuttongo"
                onClick={() => navigate("/")}
              >
                <i class="fa-solid fa-arrow-left left-buttonbackmain"></i>Go
                Back
              </button>
            </div>
            <h2>Welcome Back</h2>
            <p>Please login to access your training dashboard</p>
            <form
              className="login-form"
              id="login-form"
              onSubmit={handleSubmit}
            >
              <div className="login-input-container">
                <FaEnvelope className="login-left-icon" />
                <input
                  type="email"
                  name="email"
                  value={data.email}
                  onChange={handleChange}
                  placeholder="Email address"
                  required
                />
              </div>
              {/* Conditionally render email validation message */}
              {emailError && <p className="erroremail">{emailError}</p>}
              <div className="login-input-container">
                <FaLock className="login-left-icon" />
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  placeholder="Password"
                  name="password"
                  value={data.password}
                  onChange={handleChange}
                  required
                />
                <span onClick={togglePasswordVisibility}>
                  {showPassword ? (
                    <FaEyeSlash className="login-right-icon" />
                  ) : (
                    <FaEye className="login-right-icon" />
                  )}
                </span>
              </div>
              <button type="submit" disabled={loading} className="register-btn">
                {loading ? <span className="spinner"></span> : "Login"}
              </button>
            </form>

            {/* Divider with 'or' */}
            <div className="login-divider">or</div>

            {/* Social Login */}
            <div className="login-social">
              <GoogleOAuthProvider clientId="1097360627812-dacsadst13ll8aahbis1tbcn28hbvit5.apps.googleusercontent.com">
                {/* {isNativeApp ? (
                  <Button
                    onClick={handleLogingoggle}
                    bg="white" 
                    color="black" 
                    fontWeight="500" 
                    borderRadius="md" 
                    border="1px solid lightgray" 
                    padding="8px 24px" 
                    _hover={{ background: "#f1f1f1", transform: "scale(1.05)" }} 
                    boxShadow="lg" 
                    mt={4}
                  >
                    <Image
                      src={google}
                      alt="Google Logo"
                      boxSize="20px"
                      mr={2}
                    />
                    Sign in with Google
                  </Button>) 
                  : ( */}
                <GoogleLogin
                  onSuccess={handleGoogleSuccess}
                  onFailure={handleGoogleFailure}
                  render={(renderProps) => (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Link
                        to="#"
                        onClick={renderProps.onClick}
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          src={google}
                          alt="Google Login"
                          style={{ width: "40px", height: "40px" }}
                        />
                      </Link>
                    </div>
                  )}
                />
                {/* )}
                 */}
              </GoogleOAuthProvider>
              {/* <button className="facebook">
              <FaFacebookF />
            </button> */}
            </div>

            {/* Forgot and Signup buttons */}
            <div className="login-forgot-signup">
              <button onClick={redirectForgot}>Forgot password?</button>
              <br />
              <button onClick={redirectRegister}>
                Don’t have an account? Signup
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;

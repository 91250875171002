import React, { useEffect, useState } from "react";
import "../CSS/Global.css";
import Header from "./Header";
import Footer from "./Footer";

const Updates = () => {
    const [activeMessage, setActiveMessage] = useState(null);
    const [messages, setMessages] = useState([]);
    const [chatMessages, setChatMessages] = useState({});
    const [searchTerm, setSearchTerm] = useState(""); // ✅ Search state

    // Fetch updates
    const fetchData = async () => {
        try {
            const response = await fetch(`https://100billionoms.top/announcement/getuser/Users`);
            const res = await response.json();
            console.log(res);
            setMessages(res.reverse());
        } catch (error) {
            console.log(error);
        }
    };

    // Open a specific update message
    const openMessage = async (message) => {
        setActiveMessage(message.id);
        
        // Fetch chat messages when a message is clicked
        try {
            const response = await fetch(`https://100billionoms.top/announcement/${message.id}`);
            const res = await response.json();
            console.log("Chat Messages:", res);

            setChatMessages((prevChats) => ({
                ...prevChats,
                [message.id]: res
            }));
        } catch (error) {
            console.log(error);
        }
    };

    const goBackToMessages = () => {
        setActiveMessage(null);
    };

    useEffect(() => {
        fetchData();
    }, []);

    // ✅ **Filter messages based on search term**
    const filteredMessages = messages.filter(message =>
        message.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        message.description.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <>
            <Header />
            <div className="chat-container open">
                <div className="header-chat">
                    <div className="title">Updates</div>
                </div>

                <div className="chat-body">
                    {!activeMessage ? (
                        <>
                            {/* ✅ Search Bar with live filtering */}
                            <div className="search-bar">
                                <input
                                    type="text"
                                    placeholder="Search updates..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </div>

                            {/* ✅ Render filtered messages */}
                            <div className="message-list">
                                {filteredMessages.length > 0 ? (
                                    filteredMessages.map((message) => (
                                        <div
                                            key={message.id}
                                            className="message-item"
                                            onClick={() => openMessage(message)}
                                        >
                                            <div className="avatar">
                                                <img src={`https://100billionoms.top/${message.image}`} alt={message.sender} />
                                            </div>
                                            <div className="message-info">
                                                <div className="message-header">
                                                    <span className="message-name">{message.title}</span>
                                                </div>
                                                <div className="message-snippet">{message.description}</div>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <p className="no-results">No matching  found.</p>
                                )}
                            </div>
                        </>
                    ) : (
                        <div className="chat-details">
                            <button className="back-button" onClick={goBackToMessages}>
                                <i className="fa fa-arrow-left"></i> Back
                            </button>
                            <div className="chat-header">
                                <span className="sender-name"></span>
                            </div>
                            <div className="chat-messages">
                                {chatMessages[activeMessage] ? (
                                    <div className="chat-message">
                                        <img
                                            src={`https://100billionoms.top/${chatMessages[activeMessage].image}`}
                                            alt={chatMessages[activeMessage].title}
                                            className="chat-image"
                                        />
                                        <div className="chat-content">
                                            <h3 className="chat-headline">{chatMessages[activeMessage].title}</h3>
                                            <p className="chat-description">{chatMessages[activeMessage].description}</p>
                                            {chatMessages[activeMessage].url && (
                                                <a
                                                    href={chatMessages[activeMessage].url}
                                                    className="chat-button"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    Learn more
                                                </a>
                                            )}
                                        </div>
                                    </div>
                                ) : (
                                    <p>Loading...</p>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Updates;

import React, { useEffect, useState } from "react";
import "../CSS/Global.css"; // Import the updated CSS file
import Header from "./Header";
import Footer from "./Footer";
import { toast, ToastContainer } from "react-toastify";
import Select from "react-select";

const InquiryForm = () => {
  // State to manage form data
  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    parentName: "",
    school: "",
    dob: "",
    remark: "", // Removed skatedCategory from state,
    classname: "",
  });
  const [loading, setLoading] = useState(false);
  const [classname, setclassname] = useState([]);

  const fetchCenter = async () => {
    try {
      const response = await fetch(
        `https://100billionoms.top/classlevel/admin1997`
      );
      let res = await response.json();
      console.log(res);
      setclassname(res);
    } catch (error) {
      console.log(error);
    }
  };

  const activeClass = classname.filter(
    (center_name) => center_name.status === "Active"
  );

  const options2 = activeClass.map((language) => ({
    value: language.class_level,
    label: `${language.class_level}`,
  }));

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  console.log(formData);
  // Handle form submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Show loader

    console.log("Form data submitted:", formData);

    try {
      const response = await fetch("https://100billionoms.top/inquiry", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          name: formData.name,
          mobile: formData.mobile,
          parent_name: formData.parentName,
          school: formData.school,
          dob: formData.dob,
          age: formData.classname,
          remark: formData.remark,
        }),
      });

      if (response.ok) {
        toast.success("Enquiry Sent", {
          autoClose: 3000,
          closeOnClick: true,
          isClosable: true,
        });

        setFormData({
          name: "",
          mobile: "",
          parent_name: "",
          school: "",
          email: "",
          age: "",
          remark: "",
          skates_category: "",
        });
      } else {
        toast.error("Failed to send inquiry. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred while sending the inquiry.");
    } finally {
      setLoading(false); // Hide loader after request completes
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "black",
      color: "white",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "white",
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "white",
      color: "black",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: "black",
    }),
    input: (provided) => ({
      ...provided,
      color: "rgb(37, 37, 37)",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "black",
      color: "white",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#414040" : "black",
      color: "white",
      ":hover": {
        backgroundColor: "#e8e8e8",
        color: "black",
      },
    }),
  };

  console.log(formData);
  useEffect(() => {
    fetchCenter();
  }, []);
  return (
    <>
      <Header />
      <ToastContainer
        position="top-center"
        autoClose={3000} // 3 seconds timeout
        hideProgressBar={false}
        closeOnClick // Allows closing on click
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />

      <div className="inquiry-form-container">
        <h2>Enquiry Form</h2>
        <form onSubmit={handleSubmit}>
          <div className="inquiry-form-step">
            <div className="inquiry-input-group">
              <label htmlFor="name">Name (Student)</label>
              <div className="inquiry-input-wrapper">
                <i className="fa fa-user inquiry-input-icon"></i>
                <input
                  id="name"
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Enter Name"
                  required
                />
              </div>
            </div>

            <div className="inquiry-input-group">
              <label htmlFor="parentName">Parent Name</label>
              <div className="inquiry-input-wrapper">
                <i className="fa fa-user-group inquiry-input-icon"></i>
                <input
                  id="parentName"
                  type="text"
                  name="parentName"
                  value={formData.parentName}
                  onChange={handleChange}
                  placeholder="Enter Parent Name"
                  required
                />
              </div>
            </div>

            <div className="inquiry-input-group">
              <label htmlFor="mobile">Mobile Number</label>
              <div className="inquiry-input-wrapper">
                <i className="fa fa-phone inquiry-input-icon"></i>
                <input
                  id="mobile"
                  type="text"
                  name="mobile"
                  value={formData.mobile}
                  onChange={handleChange}
                  placeholder="Enter Mobile Number"
                  required
                />
              </div>
            </div>

            <div className="inquiry-input-group">
              <label htmlFor="school">Email</label>
              <div className="inquiry-input-wrapper">
                <i className="fa fa-envelope inquiry-input-icon"></i>
                <input
                  id="school"
                  type="email"
                  name="school"
                  value={formData.school}
                  onChange={handleChange}
                  placeholder="Enter Email"
                  required
                />
              </div>
            </div>

            <div className="inquiry-input-group">
              <label htmlFor="dob">Level</label>
              <div className="inquiry-input-wrapper">
                <i className="fas fa-map-marker-alt inquiry-input-icon"></i>
                <select
                  id="school"
                  name="classname"
                  value={formData.classname}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select Level</option>
                  {options2.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="inquiry-input-group">
              <label htmlFor="age">Age</label>
              <div className="inquiry-input-wrapper">
                <i className="fas fa-birthday-cake inquiry-input-icon"></i>
                <input
                  id="dob"
                  type="number"
                  name="dob"
                  value={formData.dob}
                  onChange={handleChange}
                  placeholder="Enter Age"
                  required
                />
              </div>
            </div>
          </div>

          <div className="inquiry-input-group">
            <label htmlFor="remark">Message</label>
            <div className="inquiry-input-wrapper">
              <i class="fa-solid fa-comments inquiry-input-icon textarea"></i>

              <textarea
                id="remark"
                name="remark"
                value={formData.remark}
                onChange={handleChange}
                placeholder="Enter Message"
                className="inquiry-form-textarea"
                rows="5" // Adjust height
                cols="50" // Adjust width
              ></textarea>
            </div>
          </div>

          <button
            type="submit"
            disabled={loading}
            className="inquiry-submit-btn"
          >
            {loading ? <span className="spinner"></span> : "Submit Inquiry"}
          </button>
        </form>
      </div>
      <Footer />
    </>
  );
};

export default InquiryForm;

import React, { useEffect, useState } from "react";
import { Box, Button, Image, Text, useToast } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import CartIcon from "./CartIcon";

const SingleProductPage = () => {
    const [activeImageIndex, setActiveImageIndex] = useState(0);
    const [quantity, setQuantity] = useState(1);
    const [selectedColor, setSelectedColor] = useState("");
    const [selectedSize, setSelectedSize] = useState("");
    const [data, setData] = useState({});
    const [selectedVariation, setSelectedVariation] = useState(null);
    const { id } = useParams();
    const toast = useToast();

    // const fetchData = async () => {
    //     try {
    //         let response = await fetch(`https://100billionoms.top/equipment/${id}`);
    //         let res = await response.json();
    //         console.log("Fetched Data:", res);

    //         const attributes = Array.isArray(res.attribute) ? res.attribute : JSON.parse(res.attribute || "[]");
    //         const productGallery = Array.isArray(res.product_gallery) ? res.product_gallery : JSON.parse(res.product_gallery || "[]");
    //         const variations = Array.isArray(res.variation) ? res.variation : JSON.parse(res.variation || "[]");

    //         setData({
    //             ...res,
    //             attributes,
    //             productGallery,
    //             variations,
    //         });

    //         // Set default variation (first one if available)
    //         if (variations.length > 0) {
    //             const defaultVariation = variations[0];
    //             setSelectedColor(defaultVariation.attributes?.color || "");
    //             setSelectedSize(defaultVariation.attributes?.numbersize || ""); // Ensure correct key
    //             setSelectedVariation(defaultVariation);
    //         }
    //     } catch (error) {
    //         console.error("Error fetching product data:", error);
    //     }
    // };

    const fetchData = async () => {
        try {
            let response = await fetch(`https://100billionoms.top/equipment/${id}`);
            let res = await response.json();
            console.log("Fetched Data:", res);

            const attributes = Array.isArray(res.attribute) ? res.attribute : JSON.parse(res.attribute || "[]");
            const productGallery = Array.isArray(res.product_gallery) ? res.product_gallery : JSON.parse(res.product_gallery || "[]");
            const variations = Array.isArray(res.variation) ? res.variation : JSON.parse(res.variation || "[]");

            // **Filter attributes that are actually used in variations**
            const filteredAttributes = attributes.filter(attr =>
                variations.some(variation => variation.attributes?.[attr.slug])
            );

            setData({
                ...res,
                attributes: filteredAttributes,
                productGallery,
                variations,
            });

            // Set default variation (first one if available)
            if (variations.length > 0) {
                const defaultVariation = variations[0];
                setSelectedColor(defaultVariation.attributes?.color || "");
                console.log(defaultVariation.attributes)
                setSelectedSize(defaultVariation.attributes?.numbersize || ""); // Ensure correct key
                setSelectedVariation(defaultVariation);
            }
        } catch (error) {
            console.error("Error fetching product data:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    // **Ensure attributes exist before accessing**
    // const colors = data.attributes?.find(attr => attr.type.toLowerCase() === "color")?.terms?.map(term => term.name) || [];
    // Ensure attributes exist before accessing
    const colors = data.attributes?.find(attr => attr.type.toLowerCase() === "color")?.terms?.map(term => term.name) || [];

    // Extract only sizes that are actually used in variations
    // Extract only sizes that have a valid price (not 0)
    // Extract available sizes from variations (supporting both "numbersize" and "size")
    const availableSizes = [
        ...new Set(
            data.variations
                ?.filter(variation => variation.price && parseFloat(variation.price) > 0) // Exclude variations with price 0
                .flatMap(variation => [variation.attributes?.numbersize, variation.attributes?.size]) // Combine both attributes
                .filter(size => size) // Remove undefined/null values
        ),
    ];

    console.log("Filtered Available Sizes:", availableSizes);


    console.log("Filtered Available Sizes:", availableSizes);


    console.log("Extracted Sizes:", availableSizes);

    // **Dynamically extract the correct size attribute**
    // const sizeAttribute = data.attributes?.find(attr => attr.type.toLowerCase() === "numbersize")?.terms?.map(term => term.name) || [];;
    // console.log("sizeAttribute", sizeAttribute)
    // // Extract the available size options
    // const sizes = sizeAttribute?.terms?.map(term => term.name) || [];

    // console.log("Extracted Sizes:", sizes);

    // **Update images based on selected variation**
    const getPrice = () => selectedVariation?.salePrice || data.sale_price || 0;
    const getStrikePrice = () => selectedVariation?.price || data.regular_price || 0;
    const images = selectedVariation?.galleryImages?.length
        ? selectedVariation.galleryImages.map(img => `https://100billionoms.top${img}`)
        : [`https://100billionoms.top${data.product_image || "/default-placeholder.png"}`];

    // **Handle variation selection**
    useEffect(() => {
        console.log("Selected Color:", selectedColor);
        console.log("Selected Size:", selectedSize);
        console.log("Available Variations:", data.variations);
    
        if (data.variations?.length > 0) {
            const matchingVariation = data.variations.find(
                v =>
                    (!selectedColor || v.attributes?.color === selectedColor) &&
                    (!selectedSize || v.attributes?.numbersize === selectedSize || v.attributes?.size === selectedSize) // Match both types
            );
    
            console.log("Matching Variation:", matchingVariation);
    
            if (matchingVariation) {
                setSelectedVariation(matchingVariation);
                setActiveImageIndex(0);
            } else {
                setSelectedVariation(null);
            }
        }
    }, [selectedColor, selectedSize, data.variations]);
    



    const handleQuantityChange = (e) => {
        let newQuantity = parseInt(e.target.value);

        // if (newQuantity ===0  || isNaN(newQuantity)) {
        //     newQuantity = 1; // Reset to 1 if invalid input
        // }

        setQuantity(newQuantity);
    };



    const [cartItems, setCartItems] = useState(() => {
        const storedCart = localStorage.getItem('cart');
        return storedCart ? JSON.parse(storedCart) : [];
    });

    const handleAdd = () => {
        // Validate if the quantity is greater than 0
        const parsedQuantity = Number(quantity);

    // Validate if the quantity is a valid positive number
    if (isNaN(parsedQuantity) || parsedQuantity <= 0) {
        toast({
            title: "Invalid quantity!",
            description: "Please enter a valid quantity greater than 0.",
            status: "error",
            isClosable: true,
            position: "top",
        });
        return; // Prevent adding to cart if quantity is invalid
    }

        const existingItem = cartItems.find(item => item.id === data.id && item.size === selectedSize && item.color === selectedColor);

        if (existingItem) {
            toast({
                title: "Product already in cart!",
                status: "warning",
                isClosable: true,
                position: "top",
            });
        } else {
            const newCartItem = {
                id: data.id,
                name: data.equipment_name,
                price: getPrice(),
                sale: getStrikePrice(),
                size: selectedSize,
                color: selectedColor,
                quantity: quantity,
                image: images[0],
                product: data
            };

            const updatedCartItems = [...cartItems, newCartItem];
            setCartItems(updatedCartItems);
            localStorage.setItem('cart', JSON.stringify(updatedCartItems));

            toast({
                title: "Product added to cart!",
                status: "success",
                isClosable: true,
                position: "top",
            });
        }
    };
    console.log(quantity)

    return (
        <>
            <Header />
            <div className="singlepagemainget">
                <div className="page-container">
                    {/* Product Image Section */}
                    <div className="page-product-image">
                        <div className="page-carousel-container">
                            <div className="page-carousel">
                                <div
                                    className="page-carousel-track"
                                    style={{
                                        transform: `translateX(-${activeImageIndex * 100}%)`,
                                        transition: "transform 0.5s ease",
                                        display: "flex",
                                    }}
                                >
                                    {images.map((image, index) => (
                                        <div key={index} className={`page-carousel-item ${index === activeImageIndex ? "active" : ""}`}>
                                            <img src={image} alt={`Product Image ${index + 1}`} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="page-thumbnail-container">
                            {images.map((image, index) => (
                                <img key={index} src={image} alt={`Thumbnail ${index + 1}`} onClick={() => setActiveImageIndex(index)} className={index === activeImageIndex ? "active" : ""} />
                            ))}
                        </div>
                    </div>

                    {/* Product Details Section */}
                    <div className="page-product-details">
                        <h1 className="page-product-title">{data.equipment_name || "Loading..."}</h1>

                        <div className="page-product-price">
                            ₹{getPrice()} {getStrikePrice() > 0 && <del>₹{getStrikePrice()}</del>}
                        </div>

                        <div className="page-product-sku">
                            <span>SKU:</span> {data.SKU_NO || "N/A"}
                        </div>

                        {/* Color Selection */}
                        {colors.length > 0 && (
                            <div className="page-product-color">
                                <label htmlFor="color">Color:</label>
                                <div className="color-options">
                                    {colors.map((color, index) => (
                                        <div
                                            key={index}
                                            className={`color-circle ${selectedColor === color ? "selected" : ""}`}
                                            onClick={() => setSelectedColor(color)}
                                            style={{ backgroundColor: color.toLowerCase() }} // Apply color dynamically
                                        >
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}


                        {/* Number-Size Selection - Shown Only If Available */}
                        {/* Number-Size Selection - Shown Only If Available */}
                        {availableSizes.length > 0 && (
                            <div className="page-product-size">
                                <label htmlFor="size">Size:</label>
                                <div className="custom-select-wrapper">
                                    <select
                                        id="size"
                                        value={selectedSize}
                                        onChange={(e) => {
                                            console.log("Size Changed:", e.target.value);
                                            setSelectedSize(e.target.value);
                                        }}>

                                        {availableSizes.map(size => (
                                            <option key={size} value={size}>{size}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        )}




                        {/* Quantity & Add to Cart */}
                        <div className="page-add-to-cart">
                            <div className="page-quantity">
                                Quantity{" "}
                                <input type="number" value={quantity} min="1" onChange={handleQuantityChange} />
                            </div>
                            <button className="page-cart-btn" onClick={handleAdd}>
                                <i className="fas fa-shopping-cart"></i> Add to Cart
                            </button>
                        </div>
                    </div>



                </div>
                <div className="page-long-description">
                    <h2>Description</h2>
                    <p>{data.description}</p>
                </div>
            </div>
            <CartIcon cartItems={cartItems} />
            <Footer />
        </>
    );
};

export default SingleProductPage;










{/* <div className="page-related-products">
                <h2>Related Products</h2>
                <Box mb={"30px"} p={"10px"}
                >
                    <Box display={"grid"} gridTemplateColumns={{ base: "repeat(2,1fr)", sm: "repeat(2,1fr)", md: "repeat(2,1fr)", lg: "repeat(5,1fr)", xl: "repeat(6,1fr)" }} gap={"10px"} height="calc(-30px + 50vh)">
                        {
                            data.length > 0 ? data.map((ele, idx) => {
                                const percentageOff = ele.regular_price && ele.sale_price
                                    ? Math.round(((ele.regular_price - ele.sale_price) / ele.regular_price) * 100)
                                    : null;
                                return (

                                    <Box mt={"10px"} minWidth={"100%"} borderRadius={"10px"}
                                        key={idx}

                                        border={"2px solid #fafafa"}
                                        bg={"white"}
                                        boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px;"
                                        style={{
                                            filter: ele.available === "outofstock" ? 'blur(0.5px)' : 'none', 
                                            position: "relative",
                                            display: 'flex',
                                            flexDirection: 'column', 
                                            justifyContent: 'space-between', 
                                            height: '100%', 
                                        }}
                                    >
                                        {percentageOff && (
                                            <Box
                                                position="absolute"
                                                top="5px"
                                                right="5px"
                                                bg="#1e90ff"
                                                color="white"
                                                px="8px"
                                                py="3px"
                                                borderRadius="5px"
                                                fontSize="12px"
                                                fontWeight="bold"
                                                zIndex="2"
                                            >
                                                {percentageOff}% OFF
                                            </Box>
                                        )}
                                        <Link
                                       
                                        >
                                            {ele.available === "outofstock" && (
                                                <Flex
                                                    position="absolute"
                                                    top="25%"
                                                    left="50%"
                                                    transform="translate(-50%, -50%)" 
                                                    zIndex="1"
                                                >
                                                    <Button size="sm" bg="black" color={"white"}>
                                                        OUT OF STOCK
                                                    </Button>
                                                </Flex>
                                            )}
                                            <Image
                                                borderTopLeftRadius={"10px"}
                                                borderTopRightRadius={"10px"}
                                                w={"100%"}
                                                p={"20px"}
                                                
                                                alt="sliderImage"
                                                key={ele?.id}
                                                
                                                src={`https://100billionoms.top${(ele.product_image)}`}

                                            />

                                        </Link>
                                        <Box p={"10px"}>
                                           
                                            <Text
                                                mt={"3px"}
                                                fontWeight={"600"}
                                                fontSize={"14px"}
                                                style={{
                                                    display: '-webkit-box',
                                                    WebkitBoxOrient: 'vertical',
                                                    WebkitLineClamp: 1,
                                                    overflow: 'hidden',
                                                    height: '25px',
                                                    whiteSpace: 'normal',
                                                    textOverflow: 'ellipsis'
                                                }}
                                            >
                                                {ele.equipment_name}
                                            </Text>
                                            
                                            <Box mt={"5px"} >
                                                <Flex justifyContent={"space-between"} alignItems={"flex-end"}>
                                                    <Box>
                                                        <Text fontWeight={"bold"} fontSize={"13px"}>₹{ele.sale_price}</Text>
                                                        {
                                                            ele.strike_off === null ? null : <Text textDecoration={"line-through"} fontSize={"13px"} color={"rgb(151,144,139)"}>₹{ele.regular_price}</Text>
                                                        }
                                                    </Box>
                                                    <Box>


                                                        {
                                                            ele.stock_avornot === "Available" ? <Button
                                                                onClick={() => handleAdd(ele, idx)}
                                                                size={"sm"} color={"#1e90ff"} border={"1px solid #1e90ff"}>ADD</Button> :
                                                                null
                                                        }

                                                    </Box>
                                                </Flex>
                                            </Box>
                                        </Box>

                                    </Box>


                                );
                            })
                                :
                                <Box mt={"30px"} w={"100%"}>
                                    <Text fontSize={"lg"}>  currently not available...</Text>
                                </Box>
                        }
                    </Box>
                </Box>

            </div> */}
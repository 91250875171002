import { useNavigate, useParams } from 'react-router-dom';
import '../CSS/Global.css';
import GreenBag from '../IMG/greenbg.webp'
import { useEffect, useState } from 'react';

function Success() {
    // const { id } = useParams();
    const navigate=useNavigate();
    const [data,setData]=useState({})

    // const fetchData = async () => {
    //     try {
    //         const response = await fetch(`https://bigbullpaisewala.co.in/cart-orders/order/${id}`)
    //         const res = await response.json();
    //         setData(res.data)
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }

    // useEffect(() => {
    //     fetchData();
    // }, [])
    return (
        <div className="success-page">
            <div className="success-page-img-wrapper">
                <div className="success-page-img">
                    <img src={GreenBag} alt="Thank You" />
                </div>
            </div>
            <h1>Form submitted! Thank You!</h1>
            <div className="success-btn">
                <button className="success-track-btn" onClick={()=>navigate(`/profile`)} >
                    View Admission Status
                </button>
                {/* <button className="track-all-btn" onClick={()=>navigate(`/order`)}>
                    Track all orders
                </button> */}
            </div>

            <p>If you've any queries, please contact us on <span>support@radicalsportsindia.com</span></p>
        </div>
    );
}

export default Success;

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link for internal navigation
import logo from '../IMG/logo-removebg-preview.png'; // Adjust path to your logo image

const Footer = () => {
  const [data, setData] = useState([])
  const [dataBelow, setDataBelow] = useState([])
  const [dataSocial, setDataSocial] = useState([])
  const [uploadedLogo, setUploadedLogo] = useState("")

  const fetchLogodata = async () => {
    try {
      const response = await fetch(`https://100billionoms.top/logo`);
      const res = await response.json();
      console.log(res);
      if (res.data.logo) {
        setUploadedLogo(`https://100billionoms.top/${res.data.logo}`);
      }
    } catch (error) {
      console.error("Error fetching logo:", error);
    }
  };

  const fetchData = async () => {
    try {
      const response = await fetch(`https://100billionoms.top/footerhead`)
      const res = await response.json();
      setData(res);
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  }

  const fetchDataDetailsMid = async () => {
    try {
      const response = await fetch(`https://100billionoms.top/getintouchdetails/phone/alldetails`)
      const res = await response.json();
      setDataBelow(res);
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  }

  const fetchDataSocial = async () => {
    try {
      const response = await fetch(`https://100billionoms.top/socialicon/social-detail/alldetails`)
      const res = await response.json();
      setDataSocial(res);
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  }


  const [getinheading, setGetInHeading] = useState("")
  const fetchDataGetInTouch = async () => {
    try {
      const response = await fetch(`https://100billionoms.top/getintouchhead`)
      const res = await response.json();

      setGetInHeading(res.data.heading);
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchLogodata();
    fetchDataGetInTouch();
    fetchDataDetailsMid();
    fetchDataSocial();
  }, [])
  return (
    <footer className="ft-container" id="footer">
      <div className="ft-section">
        <img src={uploadedLogo} alt="Radical sports logo" className="ft-logo" />
        <p className="ft-text">#BELIEVEROFCHANGER</p>
      </div>
      {
        data.map((ele, idx) => {
          return (
            <>
              <div className="ft-section">
                <h3 className="ft-title">{ele.name}</h3>
                <ul className="ft-list">
                  {
                    ele.footermiddles.map((ele, idx) => {
                      return <li className="ft-item"><Link to={`/${ele.slug}`} className="ft-link">{ele.name}</Link></li>
                    })
                  }


                </ul>
              </div>
            </>
          )
        })
      }

      {/* € */}
      <div className="ft-section">
        <h3 className="ft-title">{getinheading}</h3>
        {
          dataBelow.map((ele, idx) => {
            return (
              <>
                <div className="ft-contact">
                  <i className={`${ele.icon} ft-icon`}></i>
                  {ele.department_name}
                </div>
              </>
            )
          })
        }
        {/* <div className="ft-contact">
          <i className="fa fa-phone ft-icon"></i>
          +919820163394
        </div>
        <div className="ft-contact">
          <i className="fa fa-envelope ft-icon"></i> Info@radicalsportsindia.com
        </div>
        <div
          className="ft-contact"
          onClick={() => window.open('https://www.google.com/maps?q=2nd+Floor,+Ajanta+Shopping+Centre,+225,+Daftary+Road,+opp.+Surbhi+Sweets,+near+Railway+Station,+Malad,+Malad,+Malad+East,+Mumbai,+Maharashtra+400097', '_blank')}
          style={{ cursor: 'pointer' }}
        >
          <i className="fa fa-map-marker ft-icon"></i> Radical Sports Academy, 2nd Floor, Ajanta Shopping Centre, 225, Daftary Road, opp. Surbhi Sweets, near Railway Station, Malad, Malad, Malad East, Mumbai, Maharashtra 400097
        </div> */}
        <div className="ft-socials">
          {
            dataSocial.map((ele, idx) => {
              return <a key={idx} href={ele.extra1} className="ft-social-link" target="_blank" rel="noopener noreferrer">
                <i className={ele.icon}></i>
              </a>

            })
          }
        </div>
        {/* <div className="ft-socials">
          <a href="https://www.facebook.com/RadicalSkatingAcademy" className="ft-social-link" target="_blank" rel="noopener noreferrer">
            <i className="fa-brands fa-facebook"></i>
          </a>
          <a href="https://www.instagram.com/radicalskatingacademy" className="ft-social-link" target="_blank" rel="noopener noreferrer">
            <i className="fa-brands fa-instagram"></i>
          </a>
          <a href="https://www.youtube.com/@radicalsportsacademy5024" className="ft-social-link" target="_blank" rel="noopener noreferrer">
            <i className="fa-brands fa-youtube"></i>
          </a>
          <a href="https://www.linkedin.com/company/radical-skating-academy" className="ft-social-link" target="_blank" rel="noopener noreferrer">
            <i class="fa-brands fa-linkedin"></i>
          </a>
        </div> */}
      </div>
      <div className="ft-bottom">
        2024 ©  Created by :{' '}
        <a href="https://100billiontech.com/" target="_blank" rel="noopener noreferrer">100billion Tech</a>
      </div>
    </footer>
  );
};

export default Footer;
import Login from '../Login';
import Register from '../Register';
import Forgot from '../Forgot';
import Admission from '../AdmissionForm'; 
import Enquiry from '../EnquiryForm'
import Payment from '../Payment'
import Schedule from '../Schedule'
import Profile from '../Profile'
import Contact from '../Contact'
import About from '../About'
import Products from '../Products';
import Cart from '../Cart';
import Address from '../Address';
import EditAddress from '../EditAddress';
import { Route, Routes } from 'react-router-dom';
import Home from '../Home';
import ComingSoon from '../ComingSoon';
import PrivacyPolicy from '../PrivacyPolicy';
import TermsAndConditions from '../TermsandConditions';
import Order from '../Orders';
import Orderdesc from '../OrderDescription';
import ProtectedRoute from "./ProtectedRoute"
import HomeApp from '../HomeApp';
import Updates from '../Updates';
import SingleProductPage from '../SingleProductPage';
import Thankyou from '../SuccessPage';
import Success from '../Sucess';
import Gallery from '../Gallery';
import GalleryDeep from '../GalleryDeep';
import Centers from '../Centers';
import FooterApp from '../FooterApp';


function AllRoute() {
    return (
        <>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/forgot" element={<Forgot />} />
                <Route path="/gallery" element={<Gallery />} />
                {/* <Route path="/help" element={<Help />} /> */}
                <Route path="/" element={<HomeApp />} />
                <Route path="/admission" element={<ProtectedRoute><Admission /></ProtectedRoute>} />
                
                <Route path="/enquiry" element={<ProtectedRoute><Enquiry /></ProtectedRoute>} />
                <Route path="/payment" element={<ProtectedRoute><Payment /></ProtectedRoute>} />
                <Route path="/schedule" element={<ProtectedRoute><Schedule /></ProtectedRoute>} />
                <Route path="/equipment" element={<Products />} />
                <Route path="/cart" element={<Cart />} />
                <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/about" element={<About />} />
                <Route path="/address" element={<ProtectedRoute><Address /></ProtectedRoute>} />
                <Route path="/coming-soon" element={<ComingSoon />} />
                <Route path="/privacy" element={<PrivacyPolicy />} />
                <Route path="/terms" element={<TermsAndConditions />} />
                <Route path="/updates" element={<Updates />} />
                <Route path="/success" element={<Success />} />
                <Route path="/centers" element={<Centers />} />
                {/* <Route path="/single" element={<ProductPage />} /> */}
                <Route path="/thankyou/:id" element={<ProtectedRoute><Thankyou /></ProtectedRoute>} />
                <Route path="/product-page/:id" element={<SingleProductPage />} />
                <Route path="/orders" element={<ProtectedRoute><Order /></ProtectedRoute>} />
                <Route path="/address/:id" element={<ProtectedRoute><EditAddress /></ProtectedRoute>} />
                <Route path="/event-gallery/:id" element={<ProtectedRoute><GalleryDeep /></ProtectedRoute>} />
                <Route path="/order-description/:id" element={<ProtectedRoute><Orderdesc /></ProtectedRoute>} />
            </Routes>
            <FooterApp/>
        </>
    )
}
export default AllRoute;
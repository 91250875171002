import React, { useEffect, useState } from "react";
import "../CSS/Global.css";
import img1 from "../IMG/gridnull.jpg"
import Header from "./Header";
import { Link } from "react-router-dom";
import Footer from "./Footer";

const images = [
  { id: 1, category: "Photography", src: img1 },
  { id: 2, category: "Travel", src: img1 },
  { id: 3, category: "Nature", src: img1 },
  { id: 4, category: "Fashion", src: img1 },
  { id: 5, category: "Life Style", src: img1 },
  { id: 6, category: "Photography", src: img1 },
  { id: 7, category: "Travel", src: img1 },
  { id: 8, category: "Nature", src: img1 },
  { id: 9, category: "Fashion", src: img1 },
  { id: 10, category: "Life Style", src: img1 },
  { id: 11, category: "Photography", src: img1 },
  { id: 12, category: "Travel", src: img1 },
];

const offices = [
  {
    id: 1,
    name: "Malad",
    address: "Malad, Upper Govind Nagar, Malad (E, Mumbai, Maharashtra 400097",
    type: "Corporate office",
    image: img1,
  },
  {
    id: 2,
    name: "Goregaon",
    address: "Rosary Church Campus, M.G. Road Cardinal Gracias Nagar, Goregaon West, Mumbai, Maharashtra 400104",
    type: "Registered office",
    image: img1,
  },
  {
    id: 3,
    name: "Malad",
    address: "Kailash Puri, Plot No. 139, Malad, Upper Govind Nagar, Malad (E, Mumbai, Maharashtra 400097",
    type: "Branch office",
    image: img1,
  },
  {
    id: 4,
    name: "Malad",
    address: "Kailash Puri, Plot No. 139, Malad, Upper Govind Nagar, Malad (E, Mumbai, Maharashtra 400097",
    type: "Tech Hub",
    image: img1,
  },
  {
    id: 5,
    name: "Mumbai",
    address: "5th floor, Andheri East, Mumbai - 400059.",
    type: "Sales Office",
    image: img1,
  },
  {
    id: 6,
    name: "Hyderabad",
    address: "Ground floor, Hitech City, Hyderabad - 500081.",
    type: "Development Center",
    image: img1,
  },
];

const categories = ["All", "Photography", "Travel", "Nature", "Fashion", "Life Style"];

const Gallery = () => {
  const [selectedCategory, setSelectedCategory] = useState("All");
const [data,setData]=useState([])
  const filteredImages =
    selectedCategory === "All"
      ? images
      : images.filter((image) => image.category === selectedCategory);


      const fetchData = async () => {
        try {
            const response = await fetch("https://100billionoms.top/gallery");
            const res = await response.json();
            setData(res.gallery);
        } catch (error) {
            console.log(error);
        }
    };

    console.log(data);
    useEffect(()=>{
fetchData();
    },[])

  return (
    <>
      <Header />
      <div className="gallery-container">
        {/* Header */}
        <h1>Welcome to Radical Sports Gallery</h1>
        <p>With Best  Skating Events</p>

        {/* Filter Menu */}
        {/* <div className="filter-menu">
        {categories.map((category) => (
          <button
            key={category}
            className={selectedCategory === category ? "active" : ""}
            onClick={() => setSelectedCategory(category)}
          >
            {category}
          </button>
        ))}
      </div> */}

        {/* Masonry Grid */}
        <div className="maindivgallery">
          <div className="offices-container">
            {data.map((office) => (
              <div key={office.id} className="office-card">
                <div className="office-image">
                  <img src={`https://100billionoms.top/${office.main_image}`} alt={office.name} />

                </div>
                <div className="office-info">
                  <h3>{office.title}</h3>
                  <p className="officeinfoptag">
                    {office.description}
                  </p>
                  <Link to={`/event-gallery/${office.id}`} className="directions-btn">
                    <i className="fas fa-external-link-alt"></i> View Gallery
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default Gallery;
